@value unit, sidebar-background-color, navigation-background-color, line-color, font-size-smaller, unit from '../../ring-globals.css';
@value button from '@jetbrains/ring-ui/components/button/button.css';

a.button:hover {
  text-decoration: inherit;
}

.sidebar {
  position: absolute;

  z-index: 1; /* Need for correct scrolling work. See TW-60668 */

  overflow: hidden; /* Fix wrong page size in Edge */

  border-right: 1px solid line-color;

  background-color: sidebar-background-color;
}

.sidebarPlaceholder {
  flex-grow: 1;
}

.sidebar-fixed {
  position: fixed;
}

.sidebar-panel-wrapper {
  display: flex;
  flex: 1 1 auto;
}

.sidebar-panel {
  flex: 1 1 auto;
}

.resizable {
  display: flex;
  flex-direction: column;
}

.resizable-right-handle {
  z-index: 20;
}

.main-panel {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 0;
}

.content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  padding: calc(unit * 2) calc(unit * 4) 0 calc(unit * 4);
}

.collapsedSidebar .sidebar-panel-wrapper {
  flex-grow: 0;
}

.collapsedSidebar .footer {
  flex-grow: 1;

  border-top: none;
}

.router {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}
