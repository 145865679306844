@value unit, font-size-smaller, hover-background-color from '../../../../../ring-globals.css';

.row {
  font-size: font-size-smaller;

  &:hover {
    background-color: hover-background-color;
  }
}

.root {
  margin-bottom: calc(unit / 2);

  overflow-wrap: break-word;
}

.branch {
  margin-right: calc(unit / 2);
}

.canChange {
  position: relative;
}
