.heading {
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;

  width: fit-content;
  max-width: 600px;

  padding-right: calc(var(--ring-unit) * 2);
  padding-bottom: var(--ring-unit);
  padding-left: var(--ring-unit);

  background-color: var(--ring-content-background-color);
}

.title.title {
  margin: 0;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.headerLink {
  padding: 0 calc(var(--ring-unit) * 1.5);
}

.active {
  font-weight: bold;
}

.description {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);

  & a {
    color: var(--ring-secondary-color);
  }
}

.export {
  margin-top: var(--ring-unit);
}
