@value unit from '../../../../ring-globals.css';

.tab-toolbar {
  display: flex;
  align-items: center;

  height: calc(4.25 * unit);

  margin-bottom: calc(unit * 2);
}
