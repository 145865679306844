@value unit from '../../../ring-globals.css';

.sidebar {
  display: flex;
  flex-direction: column;
}

.tree {
  flex: 1 1 auto;
}
