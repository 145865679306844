.input {
  margin-top: var(--ring-unit);

  background-color: var(--ring-content-background-color);
}

.label {
  margin-top: calc(var(--ring-unit) * 1.5);

  font-weight: bold;
}

.inputWrapper {
  position: relative;
}
