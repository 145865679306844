@value unit, breakpoint-middle, secondary-color, font-smaller, large-screen-media, line-color, line-height from '../../../../ring-globals.css';
@value breakpointMiddle: breakpoint-middle;
@value breakpointLargest: 1600px;
@value lineHeight: line-height;
@value statusLineHeight: calc(unit * 3);

.with-toggle {
  padding-left: calc(unit * 9);
}

.agent-status {
  min-width: calc(unit * 39);
  max-width: calc(unit * 39);
  padding-left: 0;
}

.details {
  min-width: 0;
}

.build-info {
  min-width: 0;

  padding: 0;
}

.idle,
.loadingPlaceholder {
  color: secondary-color;
}

.build {
  flex-wrap: nowrap;

  margin: 0;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.buildPlaceholderHeight {
  height: calc(lineHeight + statusLineHeight + 4px);
}

.number {
  width: 10vw;
  min-width: calc(0.1 * breakpointMiddle);
  max-width: calc(0.1 * breakpointLargest);
  margin-right: calc(unit * 2);
}

.type {
  composes: branchPath from '../../Builds/Build/Build.css';
}

.branch {
  composes: branch from '../../Builds/Build/Build.css';
}

.buildPath {
  flex: 1;
}

.statusLine {
  display: flex;
  align-items: baseline;

  height: statusLineHeight;
}

.status {
  flex-grow: 1;

  min-width: 0;
}

.stop {
  padding-right: 0;
}

.withBuild {
  margin-bottom: unit;
  padding-bottom: unit;

  box-shadow: 0 1px line-color;
}

.toggle {
  flex-shrink: 0;

  margin-left: calc(unit * -4);
}

.auth-button {
  flex-shrink: 0;
}

.ip {
  composes: font-smaller;

  padding-top: 4px;
}

.hideBorder {
  box-shadow: none;
}

.authorize {
  min-width: calc(unit * 13);
  max-width: calc(unit * 13);
}

@media large-screen-media {
  .agent-status {
    min-width: calc(unit * 43);
    max-width: calc(unit * 43);
  }
}

.icon {
  margin-right: calc(unit / 2);
}
