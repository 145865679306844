.container {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.hidden {
  display: none;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  padding-bottom: calc(var(--ring-unit) * 4);
}

.errorWrapper {
  flex-grow: 1;
}

.loader {
  flex-grow: 1;

  padding-top: calc(var(--ring-unit) * 4);
}

.imageWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.image {
  position: sticky;
  top: calc(var(--ring-unit) * 4);

  width: 493px;
  height: 326px;
}
