@value unit, icon-secondary-color, font-size, line-height, text-color from '../../../ring-globals.css';

.info {
  margin: calc(unit * -0.5) calc(unit * -3.5) unit;

  border-spacing: calc(unit * 3.5) calc(unit * 0.5);

  border-collapse: separate;

  & th,
  & td {
    padding: 0;

    text-align: start;
    vertical-align: baseline;
  }
}

.label {
  margin: 0 calc(unit / 2) 0 calc(unit * 1.5);
}

.queuedIcon {
  margin-right: 2px;

  color: icon-secondary-color;
}

.queueInfoTitle {
  font-size: font-size;
}

.timeToStartAndWaitReason {
  font-size: font-size;
  line-height: line-height;
}

.timeToStart {
  color: text-color;
}

.waitReason {
  display: inline-block;

  &::first-letter {
    text-transform: lowercase;
  }
}

.compatibleAgents.compatibleAgents {
  padding: 0;

  font-size: font-size;
  line-height: line-height;
}
