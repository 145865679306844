@value unit, link-hover-color, font-size-smaller, font-smaller-lower, selected-background-color, borders-color, main-color, link-color, text-color, border-radius, fast-ease, line-height from '../../../ring-globals.css';
@value default-branch-background-color: #8ccbff;

.build-branch {
  display: inline-block;

  box-sizing: border-box;
  max-width: 100%;
  height: 20px;
  padding: 0 unit;

  user-select: text;
  transition: color fast-ease, background-color fast-ease, box-shadow fast-ease;
  white-space: nowrap;

  border-radius: border-radius;
  background-color: selected-background-color;

  font-size: font-size-smaller;
  font-weight: normal;
  line-height: line-height;
}

.innerLink {
  display: inline-flex;

  overflow: hidden;

  width: 100%;
}

.low {
  composes: font-smaller-lower;
}

.link {
  color: link-color;

  &:hover,
  &:focus,
  &[data-focus-visible-added] {
    color: var(--ring-white-text-color);
    outline: none;
    background-color: main-color;
  }

  &:hover .innerLink,
  &:focus .innerLink,
  &[data-focus-visible-added] .innerLink {
    border-bottom-width: 0;
  }
}

.auto-trim {
  max-width: 100%;
}

.default-trim {
  max-width: calc(unit * 23);
}

.with-border {
  box-shadow: 0 0 0 1px borders-color inset;
}

.with-icon {
  color: text-color;
}

.main {
  color: text-color;
  background-color: default-branch-background-color;
}

.main,
.link:hover,
.link:focus,
.link[data-focus-visible-added] {
  box-shadow: none;

  & *::selection {
    color: white;
    background-color: link-hover-color;
  }
}

.icon {
  margin-right: calc(unit / 8);

  user-select: auto;
}
