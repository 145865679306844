@value unit, font-smaller from '../../ring-globals.css';

.comment {
  composes: font-smaller;

  flex: left;

  max-width: 742px;
  margin: 1px 32px 7px calc(unit * 3);

  white-space: pre-wrap;
}
