@value unit, contentWidth, error-color, disabled-color, icon-color, secondary-color from '../../../ring-globals.css';

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: contentWidth;
}

h1.disabled-title {
  color: disabled-color;
}

h1.disconnected-title {
  color: error-color;
}

.title {
  margin-top: calc(unit);
}

.gone-message {
  margin-top: calc(unit);
}

.status {
  margin-bottom: calc(0.5 * unit);
}

.action {
  margin-right: calc(unit);
}

.enable-wrapper {
  display: inline-block;
}

.tabs {
  composes: tabs from '../../common/OverviewTab/OverviewTab.css';

  margin-top: calc(3 * unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.row-title {
  width: calc(20 * unit);
}

.edit-link {
  margin-left: calc(unit);

  color: secondary-color;
}

.edit-icon {
  color: icon-color;
}
