.container {
  position: relative;

  display: inline-block;

  min-width: var(--ring-unit);
  padding: 0 2px;
}

.text {
  opacity: 0;
}

.inputContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
}

.input {
  padding-right: 1px;
  padding-left: 1px;
}
