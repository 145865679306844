@value unit, font-smaller-lower, icon-success-color, success-background-color, error-color, warning-color, borders-color, line-color from '../../../../../../ring-globals.css';
@value success-progress-color from '../../../../../common/BuildDuration/BuildDurationAnchor/BuildDurationAnchor.css';

.legend {
  composes: font-smaller-lower;

  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;

  margin: calc(unit * 4) calc(unit * -0.5) unit;
  padding: 0;

  list-style-type: none;

  & > li {
    padding: 0 calc(unit * 0.5);
  }
}

.marker {
  --size: calc(unit * 1.5);

  display: inline-block;

  box-sizing: border-box;

  width: var(--size);
  height: var(--size);

  margin-right: calc(unit * 0.5);

  vertical-align: -1px;

  border-radius: 50%;
}

.queue {
  composes: marker;

  box-shadow: inset 0 0 0 2px success-progress-color;
}

.success {
  composes: marker;

  background-color: success-progress-color;
}

.failed {
  composes: marker;

  background-color: error-color;
}

.canceled {
  composes: marker;

  background-color: line-color;
}

.running {
  composes: marker;

  background-color: success-background-color;
  background-image:
    linear-gradient(
      135deg,
      icon-success-color,
      icon-success-color 3.75%,
      transparent 3.75%,
      transparent 46.25%,
      icon-success-color 46.25%,
      icon-success-color 53.75%,
      transparent 53.75%,
      transparent 96.25%,
      icon-success-color 96.25%,
      icon-success-color
    );
  background-size: unit unit;
}

.queued {
  composes: marker;

  border: 1px dashed borders-color;
}

.toStart {
  color: error-color;
}

.other {
  color: warning-color;
}

.reasons {
  margin-left: unit;
}
