@value unit, line-color, font-size-smaller, secondary-color, text-color, content-background-color, fixed-z-index from '../../../ring-globals.css';

.fullBuildLog {
  position: relative;

  display: flex;
  flex-direction: column;

  color: text-color;

  background-color: content-background-color;
}

.messages {
  flex-grow: 1;
}

.messagesWrapper {
  display: flex;
  flex-grow: 1;
}

.info {
  flex-grow: 1;

  padding-left: calc(unit * 2);

  &.extraPadding {
    padding-left: calc(unit * 4);
  }
}
