@value unit, text-color, secondary-color, line-height-lower, font-size-smaller from '../../../../ring-globals.css';

.logMessageLoading {
  height: line-height-lower;

  padding-top: 1px;
  padding-left: calc(unit * 3.5);

  color: secondary-color;

  font-size: font-size-smaller;
}

.runningText {
  display: inline-block;

  margin-left: calc(unit / 2);

  color: text-color;
}
