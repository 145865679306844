@value unit, icon-secondary-color, success-color, error-color, text-color, icon-error-color, icon-success-color from '../../../../ring-globals.css';

.statuses > * {
  margin-right: calc(unit * 1.5);

  white-space: nowrap;

  color: text-color;
}

.heading-link,
.text {
  color: text-color;
}

.icon {
  margin-right: calc(unit / 2);

  &.failed.failed {
    color: icon-error-color;
  }

  &.running {
    color: icon-secondary-color;
  }
}

.successful {
  color: success-color;

  & .icon {
    color: icon-success-color;
  }
}

.failed {
  color: error-color;

  & .icon {
    color: icon-error-color;
  }
}
