.header {
  display: flex;
  align-items: baseline;
}

.title {
  margin: 0;

  padding: 2px 0 var(--ring-unit);

  font-weight: bold;
}

h2.title {
  margin-bottom: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.collapsible {
  margin-left: calc(var(--ring-unit) * -4);
  padding-top: 0;
  padding-bottom: calc(var(--ring-unit) - 2px);
  padding-left: 6px;
}

.toggle {
  color: inherit;

  font-size: inherit;
  font-weight: inherit;
}

.toggleWithEditing {
  padding-right: 2px;
}

.toggleIcon.toggleIcon {
  color: var(--ring-icon-secondary-color);
}

.editNameButton {
  padding: 0 2px;
}

.right {
  margin-left: auto;
}

.detailsLink {
  display: inline-block;

  margin-top: 2px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.input {
  margin-left: 2px;

  background-color: var(--ring-content-background-color);
}

.unsavedMarker {
  margin-left: calc(var(--ring-unit) / 2);
}
