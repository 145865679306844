@value unit, selected-background-color, main-color from '../../../ring-globals.css';

.wrapper {
  display: inline-block;
}

.popup {
  max-height: 50vh;
}

.popupContent {
  padding: calc(unit * 2) 0 calc(unit * 3);

  text-align: left;
}

.hint {
  width: calc(unit * 28);
}

.titleLink {
  display: block;

  padding: 0 calc(unit * 4);

  line-height: calc(unit * 3);

  &:hover,
  &:focus,
  &[data-focus-visible-added] {
    background-color: selected-background-color;
  }

  &:focus,
  &[data-focus-visible-added] {
    outline: none;
    box-shadow: inset 2px 0 main-color;
  }
}

.text {
  margin-left: calc(unit / 4);
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}
