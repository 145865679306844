@value unit, message-background-color, line-color, border-radius, line-height, secondary-color, font-smaller, icon-secondary-color from '../../../../ring-globals.css';

.dialog {
  width: calc(100vw - unit * 4 * 2);
  height: calc(100vh - unit * 2.5 * 2);
}

.overlay {
  z-index: 9;

  padding: 0;

  background-color: message-background-color;
}

.wrapper {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.header {
  padding: calc(unit * 2) calc(unit * 4) calc(unit * 4);
}

.heading.heading {
  margin: 0;
  padding: 0;

  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.content {
  overflow: hidden;
  overflow-y: auto;

  padding: 0 calc(unit * 4) calc(unit * 4);
}

.item {
  margin-bottom: calc(unit * 3);

  &:last-child {
    margin-bottom: 0;
  }
}

.itemHeading {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  margin-bottom: unit;
}

.name {
  display: inline-block;

  margin-right: calc(unit / 2);

  font-weight: bold;
}

.fileName {
  composes: font-smaller;

  color: secondary-color;
}

.name,
.text,
.artifact,
.link {
  line-height: line-height;
}

.bold {
  font-weight: bold;
}

.number {
  display: inline-block;
}

.showGraph {
  height: inherit;
  padding: 0 unit;

  color: icon-secondary-color;

  line-height: line-height;
}

.image,
.video {
  max-width: 100%;

  border: 1px solid line-color;
  border-radius: border-radius;
}
