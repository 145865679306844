@import '~@jetbrains/ring-ui/components/global/variables.css';

.popup {
  padding: calc(var(--ring-unit) * 2) 0 calc(var(--ring-unit) * 3);
}

.list {
  margin: 0;
  padding: 0;

  list-style-type: none;
}
