@value secondary-color, line-height, font-size, warning-color, unit from '../../../ring-globals.css';

.description {
  margin-bottom: calc(unit * 2);

  color: secondary-color;

  font-size: font-size;
  font-weight: normal;
  line-height: line-height;
}

.data {
  width: 100%;
}

.branch-select {
  display: block;

  margin-bottom: calc(unit * 2);

  & > button {
    max-width: 100%;
  }
}

.preview {
  margin-bottom: calc(unit * 2);

  & .icon {
    display: block;
  }
}

.caption {
  display: block;

  margin-bottom: unit;

  font-size: 12px;
}

.format-select {
  margin-bottom: calc(unit * 2);
}

.warning {
  margin-top: unit;

  color: warning-color;
}

.warning-icon {
  margin-right: 4px;
}
