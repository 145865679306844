@value unit, font, ellipsis, error-color, success-color from '../../../../../ring-globals.css';

.agent-line {
  composes: font;

  display: flex;
  align-items: baseline;
}

.info {
  composes: ellipsis;

  flex-grow: 1;
}

.last-active {
  flex-shrink: 0;

  margin-left: calc(unit * 2);
}

.connected {
  color: success-color;
}

.disconnected {
  color: error-color;
}
