@value unit, text-color, font-size-smaller, icon-error-color, icon-success-color, fast-ease, icon-color, secondary-color from '../../../../../ring-globals.css';

.filter {
  display: flex;
  flex-direction: column;

  margin-bottom: calc(unit * 2);
}

.firstFiltersLine,
.seccondFiltersLine {
  display: flex;
  align-items: baseline;

  flex-direction: row;

  flex-wrap: wrap;
}

.stateButton.active .ignoredIcon,
.stateButton:hover .ignoredIcon,
.stateButton.active .mutedIcon,
.stateButton:hover .mutedIcon {
  color: text-color;
}

.stateButton.active .failedIcon,
.stateButton:hover .failedIcon {
  color: icon-error-color;
}

.stateButton.active .passedIcon,
.stateButton:hover .passedIcon {
  color: icon-success-color;
}

.stateIcon {
  transition: color fast-ease;

  color: icon-color;
}

.filterLabel {
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit / 2);
}

.count {
  color: secondary-color;

  font-size: font-size-smaller;
}

.viewSelect,
.statusSelect {
  margin-right: unit;
}

.searchButton,
.filterOnlyNew,
.filterWithoutInvestigator {
  margin-right: calc(unit * 2);
}

.viewSelect,
.statusSelect,
.searchButton,
.filterOnlyNew,
.filterWithoutInvestigator {
  margin-bottom: calc(unit * 2);

  &:last-child {
    margin-right: 0;
  }
}

.checkboxSection {
  display: flex;
  flex-wrap: nowrap;
}

.scopeFilter {
  margin-bottom: calc(unit * 2);
}
