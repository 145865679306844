@value unit, icon-secondary-color, main-color from '../../ring-globals.css';

.arrow {
  position: absolute;

  top: unit;
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: icon-secondary-color;

  line-height: normal;
}

.visible {
  transition: none;

  opacity: 1;
}
