@value unit, icon-secondary-color, secondary-color, hover-background-color from '../../../../../ring-globals.css';

.cell {
  display: flex;

  align-items: baseline;

  height: calc(3 * unit);

  user-select: none;

  line-height: calc(3 * unit);
}

.mutedName {
  color: secondary-color;
}

.chevron {
  color: icon-secondary-color;
}

.label {
  margin: 0 calc(0.5 * unit);

  white-space: nowrap;

  color: secondary-color;
}
