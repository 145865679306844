@value unit from '../../../ring-globals.css';

.customLinkWrapper.customLinkWrapper {
  padding: 0;

  background: none;
}

.customLink {
  display: inline-block;

  box-sizing: border-box;

  height: calc(unit * 4);
  padding: 3px calc(unit * 2) 5px;
}
