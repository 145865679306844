@value unit, link-color, icon-color, border-hover-color, main-color, ease from '../../ring-globals.css';

.dropdown {
  margin-left: calc(unit / 2);

  vertical-align: -3px;
}

.customLinkIcon {
  margin-right: unit;

  transition: color ease;

  color: icon-color;
}

.experimentIcon {
  composes: customLinkIcon;

  margin: 0 6px 0 -2px;
}

.customLink:hover .customLinkIcon,
.customLink:focus .customLinkIcon,
.customLink[data-focus-visible-added] .customLinkIcon {
  transition: none;

  color: border-hover-color;
}

.customLink:active .customLinkIcon {
  transition: none;

  color: main-color;
}

.customLink.plugin.plugin {
  padding: 0;
}

/* stylelint-disable-next-line selector-max-specificity */
.customLink.plugin.plugin:hover,
.customLink.plugin.plugin:focus {
  background: none;
}
