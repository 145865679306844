@value unit from '../../../ring-globals.css';

.buttons {
  position: relative;

  margin-top: calc(4 * unit);
}

.button {
  margin-right: calc(unit);
}
