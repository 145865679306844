@value jobWidth: 196px;

.tile {
  --text-wrapper-background: rgba(var(--ring-content-background-components), 0.7);

  position: absolute;

  overflow: hidden;

  box-sizing: border-box;

  width: jobWidth;
  padding: var(--ring-unit);

  color: var(--ring-text-color);

  border-radius: var(--ring-border-radius);
}

.tile:hover {
  --text-wrapper-background: rgba(var(--ring-hover-background-components), 0.7);

  text-decoration: none;

  color: var(--ring-text-color);

  background-color: var(--ring-hover-background-color);
}

.icon {
  display: block;

  height: 14px;
  margin-bottom: 2px;
}

.icon.icon > svg {
  margin-top: -1px;

  vertical-align: 1px;
}

.name {
  position: relative;
  z-index: 1;

  display: inline-block;
  overflow: hidden;

  max-width: 100%;

  text-overflow: ellipsis;

  border-radius: var(--ring-border-radius);

  background-color: var(--text-wrapper-background);

  line-height: var(--ring-line-height-lower);
  backdrop-filter: blur(1px);
}
