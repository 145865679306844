.columns {
  display: flex;

  width: 200%;
  max-height: 100%;
}

.primary,
.secondary {
  overflow-x: hidden;
  overflow-y: auto;
  flex-basis: 50%;

  box-sizing: border-box;

  padding: 0 calc(var(--ring-unit) *4) calc(var(--ring-unit) *4);
}

.secondary {
  transition: transform 600ms ease-out, opacity 200ms ease-out;

  transform: translateX(0);

  opacity: 0;

  background-color: var(--ring-sidebar-background-color);
}

.secondaryVisible {
  transition-duration: 200ms;
  transform: translateX(-100%);

  opacity: 1;
}
