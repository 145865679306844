@value font, font-smaller, secondary-color, icon-secondary-color, link-color from '../../../../ring-globals.css';

.agentPoolLink {
  composes: font;

  color: link-color;
}

.secondary {
  composes: font-smaller;

  color: secondary-color;

  & .icon {
    color: icon-secondary-color;
  }
}

.onlyText {
  overflow: hidden;
}
