@value unit, line-color, secondary-color, font-size-smaller, line-height-lowest, content-background-color from '../../../../../ring-globals.css';

.header {
  position: sticky;

  top: 0;

  background-color: content-background-color;

  &.header {
    margin-top: calc(unit * 2);
    padding-top: unit;
    padding-bottom: 0;
  }

  & > div {
    padding-bottom: unit;

    color: secondary-color;
    border-bottom: 1px solid line-color;

    font-size: font-size-smaller;

    line-height: line-height-lowest;

    &:first-child {
      padding-left: 0;
    }
  }

  &:hover {
    background-color: content-background-color;
  }
}
