@value unit, icon-secondary-color, icon-hover-color from '../../../../ring-globals.css';
@value chevronWidth: 24px;

.chevronButton {
  padding: 0 5px;

  & .chevronIcon {
    transition: none;

    vertical-align: middle;

    color: icon-secondary-color;

    line-height: chevronWidth;
  }

  &:hover,
  &.hovered {
    & .chevronIcon {
      color: icon-hover-color;
    }
  }
}

.fakeChevronButton {
  width: chevronWidth;
  height: calc(2 * unit);
}
