@value unit, line-color, hover-background-color, selected-background-color, main-color, font-size-smaller, text-color, link-color, line-height-lower from '../../../../ring-globals.css';

.dialog {
  width: 600px;
}

.dialogContent {
  overflow-x: hidden;

  padding-right: 0;
  padding-left: 0;
}

.build {
  display: block;

  border-radius: 0;

  outline: 0;

  box-shadow: 0 1px line-color;

  &:hover {
    text-decoration: none;

    background-color: hover-background-color;
  }

  &:focus,
  &[data-focus-visible-added] {
    background-color: selected-background-color;
    box-shadow: inset 2px 0 main-color;
  }
}

.buildContent {
  padding-right: calc(unit * 4);
  padding-left: calc(unit * 4);
}

.firstRow {
  display: flex;

  font-size: font-size-smaller;
}

.numberWithBranch {
  flex-grow: 1;
}

.label {
  text-align: right;

  color: text-color;
}

.number {
  color: link-color;
}

.branch {
  margin-left: unit;
}

.loader,
.emptyMessage {
  margin-left: calc(unit * 4);
}

.status {
  line-height: line-height-lower;
}

.statusIcon {
  margin-right: calc(unit / 2);
}
