@value unit, link-color, font-size from '../../../../../../../ring-globals.css';

.wrapper {
  display: flex;
  align-items: flex-end;

  height: 100%;
}

.popup {
  width: calc(unit * 60);
  margin-top: calc(unit / -2);
  padding: calc(unit * 1.5) calc(unit * 1.5) calc(unit * 2);

  &.average {
    width: unset;
  }
}

.content {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: max-content auto;
  grid-gap: calc(unit / 2) calc(unit * 2);

  & > div {
    overflow: hidden;
  }
}

.loading {
  margin-bottom: calc(unit / 2);
}

.buildPathLinks {
  color: link-color;
}

.durationValue {
  font-weight: bold;
}

.statusHeading {
  margin-bottom: calc(unit * 2 - unit / 2);
}

.buildValue {
  display: flex;
  align-items: baseline;
}

.buildNumber {
  margin-right: calc(unit * 1.5);

  font-size: font-size;
}
