@value unit from '../../../ring-globals.css';

.popup {
  z-index: 100;

  min-width: calc(unit * 58);

  text-align: left;
}

.header {
  margin-top: calc(2 * unit);

  font-size: calc(3 * unit);
}
