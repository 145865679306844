@value error as error_ from '../TwoFactorAuthSettings/TwoFactorAuthSettings.css';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
}

.form {
  width: 240px;
}

.heading {
  font-weight: bold;
}

.password,
.wide-button {
  width: 100%;
}

.password {
  margin-bottom: calc(var(--ring-unit) * 2);
}

.error {
  composes: error_;
}

.error-placeholder {
  height: 60px;
  padding-top: 8px;
}
