@value unit, line-color, hover-background-color from '../../../../../../ring-globals.css';

.title {
  margin-right: auto;

  color: heading-color;

  font-weight: bold;
}

.header {
  composes: row from '../../../../../CollapsibleLine/CollapsibleLine.css';

  justify-content: flex-start;
}

.headless {
  display: none;
}

.line {
  composes: line from '../../../../../CollapsibleLine/CollapsibleLine.css';

  margin-top: 0;
  margin-bottom: 0;
}

.arrow {
  composes: arrow from '../../../../../CollapsibleLine/CollapsibleLine.css';
}

.content {
  margin-bottom: calc(unit * 3);
}

.nonBuilt {
  &:hover {
    background: hover-background-color;
  }
}

.container {
  padding: 0 calc(unit * 2);
}
