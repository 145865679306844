@value unit, warning-color, popup-border-color, main-color, error-color, icon-error-color, icon-warning-color from '../../../../ring-globals.css';
@value info-color: #fff;
@value hideButtonContainerWidth: calc(unit * 18);

.container {
  position: relative;

  display: flex;
  justify-content: stretch;

  box-sizing: border-box;

  width: 100%;
  padding-left: calc(unit / 2);

  border-top: 1px solid var(--ring-line-color);
}

.renderBeforeHeader {
  border-top: none;
  border-bottom: 1px solid var(--ring-line-color);
}

.content {
  width: 100%;
  padding: unit hideButtonContainerWidth unit calc(unit / 2);
}

.hideButtons {
  position: absolute;
  top: 6px;
  right: 0;

  padding-right: 0;

  padding-left: 0;

  text-align: right;
}

.error {
  color: error-color;
}

.warning {
  color: warning-color;
}

.info {
  color: info-color;
}

.toggleButton {
  position: absolute;
  top: 7px;

  right: 0;

  color: main-color;
}

.icon {
  padding-top: unit;

  color: currentColor;
}

.error .icon {
  color: icon-error-color;
}

.warning .icon {
  color: icon-warning-color;
}

.popupIcon {
  margin-right: unit;
}

.truncatedContainer {
  cursor: pointer;
}

.dialog {
  width: calc(unit * 100);
  min-width: calc(unit * 100);
  max-height: calc(unit * 70);
}

.contentPanel {
  border-bottom: 1px solid popup-border-color !important;
}

.hideExplanation {
  margin-top: 0;
}

.fallbackHealthItem {
  height: calc(unit * 4.5);

  background-color: var(--ring-message-background-color);
}
