@value unit from '../../../ring-globals.css';

.firstLine,
.secondLine {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.firstLine {
  position: relative;
  z-index: 3;
}

.filterControl {
  margin-right: unit;
  margin-bottom: unit;
}
