@import 'react-image-lightbox/style.css';

@value unit, resetButton, line-color, secondary-color, font-smaller, link-hover-color, border-hover-color, border-radius from '../../../../../ring-globals.css';

.header {
  display: flex;
  align-items: baseline;

  margin-bottom: unit;
}

.heading {
  margin-bottom: 0 !important;
}

.showAllButton {
  padding: 0 calc(unit * 1.5);
}

.metadataImages {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(unit * 18));
  grid-gap: 8px;

  align-items: start;

  margin-bottom: calc(unit * 2);
}

.fileName {
  composes: font-smaller;

  z-index: 1;

  margin-top: calc(unit / 2);

  line-height: 14px;
  overflow-wrap: break-word;
}

.mediaImage {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  width: calc(unit * 18);
  height: calc(unit * 12);

  border: solid 1px line-color;
  border-radius: calc((unit - 2px) / 2);

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.media {
  composes: resetButton;

  width: calc(unit * 18);

  cursor: pointer;

  color: secondary-color;

  &:focus {
    border-radius: border-radius;
    outline: none;
    box-shadow: inset 0 0 0 2px border-hover-color;
  }

  &:hover {
    color: link-hover-color;
    box-shadow: none;
  }

  &:hover .mediaImage {
    border: solid 1px link-hover-color;
    box-shadow: 0 2px 8px 0 rgba(0, 42, 76, 0.1);
  }
}

.toggleMediaButton {
  composes: font-smaller;

  height: calc(unit * 14);

  padding: 0 unit;

  color: secondary-color;
}

.metadataItem {
  margin-bottom: calc(unit / 2);

  &:last-child {
    margin-bottom: 0;
  }
}

.toggleOtherButton {
  composes: font-smaller;

  margin-top: calc(unit / 2);

  padding: 0;

  color: secondary-color;
}

.lightboxLoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
