@value unit from '../../../ring-globals.css';

.wrapper {
  position: relative;

  display: inline-block;

  margin-right: calc(unit * 0.5);
}

.avatar {
  display: inline-block;
  overflow: hidden;

  vertical-align: -5px;

  color: transparent;
}

.withOutline::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border-radius: var(--ring-border-radius);

  box-shadow: 0 0 0 1px rgba(184, 209, 229, 0.4) inset;
}

.my::after {
  box-shadow: 0 0 0 1.5px var(--ring-main-color) inset;
}
