@value unit, contentWidth, font-smaller-lower, sidebar-background-color, secondary-color, link-color, disabled-color, selected-background-color, font-size, text-color from '../../../ring-globals.css';

.limitedWidth {
  max-width: contentWidth;
}

.collapsed {
  margin-left: calc(unit * 2);
}

.note {
  composes: font-smaller-lower;

  display: flex;
  align-items: center;

  margin-top: unit;

  padding: unit calc(unit * 2);

  text-align: center;

  color: secondary-color;
  border-top: 1px solid #dfe5eb;

  background-color: sidebar-background-color;
}

.noteText {
  padding-left: unit;
}

.link {
  display: flex;
  align-items: center;
  flex: 1 0;

  height: calc(unit * 4);
}

.counter {
  composes: font-smaller-lower;

  margin-left: unit;

  text-decoration: none;

  color: secondary-color;

  font-weight: normal;
}

/* stylelint-disable selector-max-specificity */
.link,
.link:hover,
.link:focus,
.link[data-focus-visible-added],
.link:active {
  & .name {
    color: text-color;
  }

  & .icon.icon.mammoth,
  & .counter {
    color: secondary-color;
  }
}
/* stylelint-enable */

.link:hover {
  background-color: selected-background-color;
}

.linkContent {
  composes: font-smaller-lower;

  display: flex;
  align-items: center;
}

.tabs,
.tabs > div:nth-child(2),
.classicTab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
