@value unit, line-height-lower, ellipsis from '../../../ring-globals.css';

.inline {
  composes: ellipsis;
}

.buildPath {
  display: inline;

  line-height: line-height-lower;

  & > * {
    display: inline;
  }
}

.buildPathLink {
  color: inherit;
}
