@value unit, font, font-size, link-color, popup-shadow-color, content-background-color from '../../ring-globals.css';
@value animation-easing: ease-out;
@value animation-duration: 300ms;
@value backgroundColor: #0077d6;
@value tipHoverBackgroundColor: #008eff;
@value icon from '@jetbrains/ring-ui/components/button/button.css';

.panel {
  display: flex;

  flex-direction: column;

  width: calc(unit * 36);

  min-height: calc(unit * 20);

  margin: unit 0 calc(unit * 2) calc(unit * 2);
}

.popup {
  z-index: 10;
}

.background {
  position: absolute;

  right: 0;
  bottom: 0;

  height: 140px;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 7px;

  height: auto;
  padding: 0;

  line-height: inherit;

  &:hover {
    cursor: pointer;
  }
}

.closeButtonIcon.closeButtonIcon,
.closeButton:active .closeButtonIcon {
  color: content-background-color;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 90%;

  margin-top: calc(unit);
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit * 2);
}

.description {
  display: flex;
  flex: 1;
  flex-direction: column;

  color: white;

  & .title {
    font-weight: bold;
  }

  & .title,
  & .text {
    margin-top: 0;
  }

  & .actions {
    margin-top: auto;
  }

  & .button {
    align-self: flex-start;

    margin-top: auto;
    margin-right: unit;
  }
}

.category.category:not(.categoryCollapsed) {
  color: white;
}

.categoryCollapsed.categoryCollapsed {
  color: currentColor;
}

.category:not(.categoryCollapsed):not(.categorySelected) {
  color: white;

  /* stylelint-disable-next-line selector-max-specificity  */
  &:hover {
    color: #bedaf4;
    box-shadow: none;
  }
}

.categorySelected:not(.categoryCollapsed),
.categorySelected.categorySelected:hover,
.categorySelected.categorySelected:active {
  box-shadow: inset 0 -3px 0 0 white;
}

.tipsContainer {
  display: flex;
  flex-wrap: wrap;
}

.tip {
  z-index: 1;

  display: flex;

  margin-right: unit;
  margin-bottom: calc(unit);
}

.tipName {
  position: relative;

  padding: 5px 16px;

  cursor: pointer;

  color: white;
  border: none;
  border-radius: 3px;
  background: transparent;

  line-height: 1.1;

  &:hover {
    background-color: tipHoverBackgroundColor;
  }

  &.withLink {
    padding: 5px 12px 5px 16px;
  }
}

.readMore {
  display: inline-block;

  padding: 0 8px;

  color: white;
  border: none;
  background: none;

  &:hover {
    color: white;
    background-color: tipHoverBackgroundColor;
  }
}

.readMoreIcon {
  margin-top: 1px;

  transform: scale(0.8);
}

.pagination {
  flex-direction: column;

  margin-top: auto;
  padding-top: calc(unit * 4);
}

.controlButton {
  z-index: 1;

  margin-right: unit;

  color: black;
  background: white;
  box-shadow: white;
}

.hintPopup {
  composes: popup from './HintPopup/HintPopup.css';
}

.hintPopupContent {
  composes: content from './HintPopup/HintPopup.css';
}

.reminderPopup {
  composes: hintPopup;

  min-width: calc(unit * 38);
  margin-left: unit;
}

.tail {
  composes: tail from './HintPopup/HintPopup.css';
}

.pages {
  composes: font;

  display: block;

  margin-bottom: calc(unit / 2);

  color: white;
}

.containerClassName {
  padding: 0;

  background-color: backgroundColor;
}

.captionClassName {
  margin: 0;
}

.titleClassName,
.descriptionClassName {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.closeButtonClassName {
  & .icon {
    color: white;
  }

  &:hover .icon {
    opacity: 0.7;
    color: white;
  }
}
