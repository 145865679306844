@value unit, font-smaller-lower, secondary-color from '../../../../ring-globals.css';

.button {
  composes: font-smaller-lower;

  margin-right: unit;
  padding: 0;

  color: secondary-color;
}
