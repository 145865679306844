@value unit from '../../../../../ring-globals.css';

.container {
  padding: 0 calc(unit * 2);
}

.list,
.item {
  display: contents;
}
