@value unit, icon-color, secondary-color, link-hover-color from '../../../ring-globals.css';

.icon {
  position: relative;
  top: 1px;

  margin-right: calc(unit / 2);

  color: icon-color;
}

.anchor {
  height: calc(unit * 2 + unit / 2);

  color: secondary-color;

  &:hover .icon {
    color: link-hover-color;
  }
}
