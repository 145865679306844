@value unit, icon-main-color, fast-ease from '../../../ring-globals.css';
@value link, icon, text, right from '../LinkWithIcon/LinkWithIcon.css';

.button {
  height: auto;

  line-height: inherit;
}

.starred.starred {
  color: icon-main-color;
}

.invisible {
  color: transparent;
}

.invisible:focus,
.invisible[data-focus-visible-added],
.wrapper:focus-within .invisible,
.beforeWrapper:focus-within + * .invisible,
.wrapper:hover .invisible,
.beforeWrapper:hover + * .invisible {
  color: inherit;
}

.hidden {
  visibility: hidden;
}
