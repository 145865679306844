@value unit, text-color, secondary-color, font-smaller, selected-background-color from '../../../ring-globals.css';

.popup {
  width: calc(unit * 37);
  margin: calc(unit * 0.5) 0 0;
  padding: calc(unit * 0.5) 0 unit;
}

.link {
  composes: item from '../../common/ActionsDropdown/ActionsDropdown.css';
}

.hoverLink {
  composes: hoverItem from '../../common/ActionsDropdown/ActionsDropdown.css';
}

.text {
  composes: font-smaller;

  white-space: normal;

  color: secondary-color;
}
