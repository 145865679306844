@value unit, lineClamp, lineClampHasOverflow, ellipsis, font-lower, font-smaller, line-height-lower, text-color, fast-ease, content-background-color, sidebar-background-color, line-color, secondary-color, popup-shadow-color, border-hover-color, border-radius, fixed-z-index, overlay-z-index from '../../../../../../ring-globals.css';
@value lineHeightLower: line-height-lower;
@value fastest-ease: 0.05s ease-out;

.wrapper {
  composes: font-lower;

  display: flex;
  flex-wrap: wrap;

  margin: 0 calc(unit * -1) unit;
}

.placeholder,
.cell {
  @value minWidth: calc(unit * 34);

  flex: 1 0 minWidth;

  min-width: minWidth;
  max-width: calc(unit * 40);
}

.placeholder {
  margin: 0 unit;
}

.cell {
  position: relative;

  box-sizing: border-box;

  height: calc(unit * 30);

  margin: unit;
}

.hover {
  composes: hover from '../../../../../common/BuildsTimeline/BuildsTimeline.css';
  composes: hover from '../../../../../common/BuildInfo/BuildInfo.css';
}

.card {
  composes: container from '../../../../../common/BuildsTimeline/BuildsTimeline.css';
  composes: container from '../../../../../common/BuildInfo/BuildInfo.css';

  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  overflow: hidden;
  flex-direction: column;

  transition: box-shadow fastest-ease, right fastest-ease, bottom fastest-ease, left fastest-ease, padding fastest-ease;

  border-radius: border-radius;

  background-color: content-background-color;
}

.expandable {
  box-shadow:
    0 0 0 1px line-color,
    0 1px 8px 0 popup-shadow-color;

  &:focus,
  &[data-focus-visible-added] {
    outline: none;
    box-shadow:
      0 0 0 2px border-hover-color,
      0 2px 16px 0 rgba(var(--ring-popup-border-components), 0.2);
  }
}

.expanded {
  z-index: fixed-z-index;
  right: calc(unit * -1);
  left: calc(unit * -1);

  padding: 0 unit;

  transition: box-shadow fast-ease, right fast-ease, bottom fast-ease, left fast-ease, padding fast-ease;

  box-shadow:
    0 0 0 1px line-color,
    0 2px 16px 0 rgba(var(--ring-popup-border-components), 0.2);

  .hover {
    z-index: overlay-z-index;
  }
}

.buildTypeInfo {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  box-sizing: border-box;

  height: calc(unit * 22);
  padding: unit;
}

.buildInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: calc(unit * 8);
  margin: 0 calc(unit * -1);

  background-color: sidebar-background-color;
  box-shadow: inset 0 1px line-color;
}

.title {
  display: flex;
  align-items: baseline;
}

.name {
  composes: lineClamp;

  -webkit-line-clamp: 2;

  box-sizing: border-box;

  max-height: calc(lineHeightLower * 2 + 3px);
  margin: -2px 0 6px -3px;
  padding: 0 calc(unit * 4.5) 2px 3px;
}

.spacer {
  display: inline-block;

  margin-top: 2px;
}

.hasOverflow {
  composes: lineClampHasOverflow;
}

.icon {
  display: inline;

  white-space: nowrap;

  &::after {
    content: '\2060';
  }
}

.card:not(.hover) .showOnHover {
  visibility: hidden;
}

.card:focus-within .showOnHover {
  visibility: visible;
}

.star.star,
.actions {
  height: auto;
  padding: 0 2px;

  line-height: normal;
}

.star.star {
  margin-right: calc(unit * -0.5);
}

.actions {
  composes: showOnHover;

  margin-left: calc(unit / 2);
}

.actionsWrapper {
  white-space: nowrap;

  &::before {
    content: '\2060';
  }
}

.moveActions {
  position: relative;
  top: lineHeightLower;
  right: calc(unit * 4.5);

  width: 0;
}

.link {
  margin-left: -1px;

  padding-left: 1px;

  color: text-color;
}

.run {
  margin-left: auto;
}

.runGroup {
  display: flex;
  align-items: baseline;

  max-width: 120px;
}

.runButton {
  composes: ellipsis;

  min-width: 0;
}

.runSplitButton {
  flex-shrink: 0;
}

.emptyStar {
  composes: showOnHover;
}

.charts {
  display: flex;

  margin-top: auto;
}

.noBuilds {
  composes: font-smaller;

  color: secondary-color;
}
