.heading {
  display: flex;

  margin-bottom: calc(var(--ring-unit) * 4);
}

.title.title {
  margin-bottom: calc(var(--ring-unit) / 2);
}

.actions {
  margin-left: auto;
}

.button {
  margin-left: var(--ring-unit);
}
