@value unit, line-height-lower, font-size-smaller, font-family-monospace, content-background-color from '../../../ring-globals.css';
@value timeWidth: 58px;

.messages {
  font-family: font-family-monospace;
  font-size: font-size-smaller;

  line-height: line-height-lower;
}

.messageItem.messageItem {
  display: block;
}

.hoverDisabled {
  & .messageFocusWrapper:hover {
    background-color: inherit;
  }
}

.grid {
  outline: none;
  background-color: content-background-color;
}

.time {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  padding-left: calc(unit * 2);
}

.message {
  margin-left: calc(unit * 2 + timeWidth - unit / 2);
}

.runningMessage {
  margin-top: calc(unit * 2);
}

.extraPadding {
  & .time {
    padding-left: calc(unit * 4);
  }

  & .message {
    margin-left: calc(unit * 4 + timeWidth - unit / 2);
  }
}
