@value unit, ellipsis, link-color, text-color, icon-hover-color, icon-secondary-color, main-color, secondary-color, line-height, font-size-smaller, icon-success-color from '../../../ring-globals.css';

.wrapper {
  display: inline-block;

  font-size: font-size-smaller;
}

.dropdown,
.anchor {
  width: 100%;
}

.wrapper .anchor {
  height: unset;
  padding: 0;

  color: text-color;

  line-height: inherit;

  &:hover,
  &:active {
    color: icon-hover-color;
  }
}

.icon {
  padding: 0;

  color: icon-secondary-color;
}

.icon.assignedToMe {
  color: main-color;
}

.icon.fixed {
  color: icon-success-color;
}

.anchor:hover .icon,
.anchor:active .icon {
  color: icon-hover-color;
}

.popup {
  display: flex;
  overflow: hidden;

  width: calc(unit * 50);

  & > div {
    width: 100%;
  }
}

.state {
  word-break: break-word;
}

.timestamp {
  white-space: nowrap;
}

.actionButton {
  margin-right: unit;

  &:last-child {
    margin-right: 0;
  }
}

.assignAction {
  height: unset;
  padding: 0;

  color: secondary-color;

  line-height: line-height;
}

.comment,
.scope {
  margin-top: unit;
}

.comment {
  overflow: hidden;
}

.commentText {
  word-break: break-word;
}

.commentTextLoader {
  display: inline-block;
}

.scope {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}

.scopeTitle {
  margin-right: calc(unit / 2);
}

.scopeList {
  overflow: hidden;
  flex: 1;
}

.entityPath {
  flex: 1;

  line-height: line-height;
}

.entityPath .entityPathLink {
  color: link-color;
}

.anchorContent {
  display: flex;
  align-items: baseline;
}

.anchorText {
  composes: ellipsis;

  display: inline-block;

  width: 100%;
  margin-left: calc(unit / 2);
}

.anchorChevron {
  margin-left: calc(unit / 4);
}

.investigationDetails {
  margin: 0 calc(unit * -2) unit;
  padding: 0 calc(unit * 2) unit;
}

.investigationDetails:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.withSeveralInvestigations .investigationDetails {
  margin-bottom: calc(unit * 2);
  padding-bottom: calc(unit * 2);
}

.withSeveralInvestigations .investigationDetails:last-child {
  margin-bottom: 0;
}

.body {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.content {
  overflow-y: auto;

  height: 100%;
  max-height: 400px;
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.withSeveralInvestigations .content {
  padding-top: calc(unit * 2);
}

.hasPermission .content {
  padding-bottom: 0;
}

.footer {
  padding: calc(unit * 2);
}

.assigneeName.highlight {
  font-weight: bold;
}
