@value unit, secondary-color from '../../../ring-globals.css';

.commentPopup {
  max-width: 300px;

  padding: unit calc(2 * unit);

  text-align: left;
}

.info {
  color: secondary-color;
}

.text {
  overflow-wrap: break-word;
}
