@value jobWidth: 196px;

.tile {
  --text-wrapper-background: rgba(var(--ring-content-background-components), 0.7);

  position: absolute;

  overflow: hidden;

  box-sizing: border-box;

  width: jobWidth;
  padding: var(--ring-unit);

  color: var(--ring-text-color);

  border-radius: var(--ring-border-radius);
}

.tile:hover {
  --text-wrapper-background: rgba(var(--ring-hover-background-components), 0.7);

  text-decoration: none;

  color: var(--ring-text-color);

  background-color: var(--ring-hover-background-color);
}

.running {
  box-shadow: 0 0 0 1px var(--ring-line-color) inset;
}

.selected {
  --text-wrapper-background: rgba(var(--ring-hover-background-components), 0.7);

  background-color: var(--ring-hover-background-color);
  box-shadow: 0 0 0 2px var(--ring-main-color) inset;
}

.progress {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
}

.progress-success {
  background-color: rgba(var(--ring-icon-success-components), 0.12);
}

.progress-failure {
  background-color: rgba(var(--ring-icon-error-components), 0.12);
}

.head {
  display: flex;
}

.icon {
  height: 14px;
  margin-bottom: 2px;
}

.icon.icon > svg {
  margin-top: -1px;

  vertical-align: 1px;
}

.left {
  margin-left: auto;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.name {
  margin-bottom: 4px;

  line-height: var(--ring-line-height-lower);
}

.status {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.status-success {
  color: var(--ring-success-color);
}

.status-failure {
  color: var(--ring-error-color);
}

.status-queued,
.status-canceled {
  color: var(--ring-secondary-color);
}

.text,
.left {
  position: relative;
  z-index: 1;

  border-radius: var(--ring-border-radius);
  backdrop-filter: blur(1px);

  background-color: var(--text-wrapper-background);
}

.name,
.status {
  display: -webkit-box;
  overflow: hidden;

  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
