@value unit, line-height-lowest, secondary-color, font-size-smaller from '../../../ring-globals.css';

.nodes {
  padding-left: 0;

  list-style-type: none;
}

.tree > .nodes {
  margin: 0;
  padding: 0;
}

.checkAll {
  display: flex;
  align-items: baseline;

  padding: calc(unit / 2) 0;
}

.checkAllCheckbox {
  line-height: line-height-lowest;
}

.checkAllCount {
  margin-left: calc(unit / 2);

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lowest;
}
