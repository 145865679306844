@value unit, font, font-size, link-color, popup-shadow-color from '../../../ring-globals.css';

.favoritesHint {
  margin-top: calc(unit * -2.5);
  margin-left: calc(unit);
}

.headerLink {
  margin-left: calc(-1 * unit);
}

.customRunHint {
  margin-top: calc(unit / 2);
  margin-left: calc(unit * 3);
}

.addAgentsPoolHint {
  margin-top: calc(unit * -2.5);
}
