@value unit, hover-background-color, font-size-larger, secondary-color, link-hover-color from '../../../../../ring-globals.css';

.row:hover {
  background-color: hover-background-color;
}

.wrapper {
  position: relative;

  color: secondary-color;

  font-size: font-size-larger;
}

.changed {
  color: link-hover-color;
}
