@value unit, secondary-color, icon-secondary-color, hover-background-color, font-size-smaller from '../../../../../ring-globals.css';

.project {
  height: calc(3 * unit);

  user-select: none;

  outline: none;

  line-height: calc(3 * unit);
}

.expandable-project {
  cursor: pointer;
}

.indented-container {
  display: flex;
  align-items: baseline;
}

.chevron {
  flex: 0 0;
}

.chevron-icon.chevron-icon {
  color: icon-secondary-color;
}

.checkbox-container {
  display: flex;
  overflow: hidden;
  align-items: baseline;

  margin-left: 3px;
}

.checkbox-cell {
  top: 2px;

  flex-shrink: 0;

  margin-left: 1px;
}

.checkbox-label.checkbox-label {
  position: relative;

  z-index: 4;

  min-width: 0;

  margin-left: calc(unit / 2);

  cursor: pointer;

  line-height: calc(3 * unit);
}

.project:hover .path-item {
  background-color: hover-background-color;
}

.project-agent-pools {
  overflow: hidden;
  flex: 1 1 calc(6 * unit);

  padding-left: calc(unit);

  white-space: nowrap;
  text-overflow: ellipsis;

  color: secondary-color;

  font-size: font-size-smaller;
}
