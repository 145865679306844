@value unit, hover-background-color, icon-secondary-color, line-height-lower from '../../../../../ring-globals.css';

.container {
  margin-bottom: calc(unit * 4);
}

.header {
  font-weight: bold;
}

.header:hover {
  background: hover-background-color;
}

.icon {
  color: icon-secondary-color;
}

.expand-handle {
  display: inline;

  height: auto;

  margin-right: calc(unit / 2);

  line-height: line-height-lower;
}
