@value unit, text-color, font-size-larger, font-size, line-height, popup-shadow-color from '../../../ring-globals.css';

.tail {
  border-color: var(--ring-popup-background-color) transparent;
}

.dark.highlight {
  & .tail {
    border-color: var(--ring-line-color) transparent;
  }
}

.popup {
  min-width: calc(unit * 35);
  padding-left: calc(unit * 2);

  color: text-color;
  border: solid calc(unit / 8) var(--ring-line-color);
  background: var(--ring-popup-background-color);

  filter: drop-shadow(0 2px 16px popup-shadow-color);

  &::before {
    box-shadow: none;
  }

  & :global(.ring-message-title) {
    font-weight: bold;
  }
}

.content {
  display: flex;
  flex-direction: column;

  color: text-color;
}

.withImage {
  padding: 0;

  & .content {
    padding: calc(unit * 2);
    padding-top: 0;

    color: text-color;
  }

  & .title {
    margin: unit 0;

    color: text-color;

    font-size: font-size-larger;
    line-height: var(--ring-line-height-lower);
  }

  & .image {
    width: 100%;
    max-height: calc(unit * 22.5);

    transform: translateY(calc(-1 * unit / 2));

    object-fit: cover;
  }
}

.helpLink {
  align-self: flex-start;

  padding-top: unit;
  padding-bottom: calc(unit / 2);

  color: var(--ring-main-color);
}

.bot {
  display: flex;
  align-items: center;

  margin-top: calc(unit * 1.5);

  & .btnPrev {
    margin-right: unit;
  }

  & .progress {
    margin-left: auto;
  }
}

.btnClose {
  position: absolute;
  top: calc(unit * 1.25);
  right: calc(unit * 1.25);

  & .closeIcon {
    color: var(--ring-secondary-color);
  }
}

.description {
  font-size: font-size;
  line-height: line-height;
}
