@value unit, secondary-color, main-color, ellipsis, font-smaller-lower from '../../../ring-globals.css';

.wrapper {
  composes: font-smaller-lower;

  display: flex;
  align-items: baseline;

  max-width: 900px;
}

.header {
  display: flex;
}

.timestamp {
  color: secondary-color;
}

.userInfo {
  margin-right: calc(unit / 2);
}

.button {
  padding-right: unit;
  padding-left: 0;

  color: secondary-color;
}

.icon {
  flex: 0 0 auto;

  margin-right: calc(unit / 2);

  color: main-color;
}
