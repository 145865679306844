@value unit, ellipsis from '../../../../../../ring-globals.css';

.wrapper {
  display: flex;
  align-items: baseline;

  white-space: nowrap;
}

.link {
  composes: ellipsis;

  flex: 0 1 auto;

  margin: 0 calc(unit / 2);
}

.link.all,
.link.noScope {
  flex: 0 0 auto;
}
