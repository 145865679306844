@value unit, font-size-smaller, line-height, line-height-lowest, line-color from '../../../../../ring-globals.css';

.col {
  padding: 0 unit;
}

.title1.title1,
.title2.title2 {
  margin: 0;
  padding: 0;

  font-size: font-size-smaller;
}

.title1.title1 {
  padding-top: calc(unit * 2);
  padding-bottom: unit;

  letter-spacing: 2px;
  text-transform: uppercase;

  border-bottom: 1px solid line-color;

  font-weight: normal;

  line-height: line-height-lowest;
}

.title2.title2 {
  font-weight: bold;
  line-height: line-height;
}
