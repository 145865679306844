@value unit, hover-background-color, secondary-color, disabled-color, line-color from '../../../ring-globals.css';

.container {
  margin: 0 calc(unit * -4) 0 calc(unit * -2);
  padding: calc(unit) calc(unit * 4) 0 calc(unit * 2);
}

.inheritedSection {
  margin: 0 calc(unit * -3) 0 calc(unit * -4);
  padding: calc(unit / 2) calc(unit * 3) calc(unit / 2) calc(unit * 4);
}

.wrapper {
  padding-left: calc(unit * 3);
}

.editable:hover {
  cursor: pointer;

  background: hover-background-color;
}

.policiesContainer {
  display: flex;

  width: 100%;
}

.rules {
  flex-direction: column;
}

.policies {
  padding: calc(unit * 2) 0;
}

.actions {
  composes: actions from '../../CleanupBuildType/RulesList/RuleItem/RuleItem.css';
}

.actionButton {
  composes: actionButton from '../../CleanupBuildType/RulesList/RuleItem/RuleItem.css';
}

.policyListing {
  flex-grow: 1;

  width: calc(unit * 67);
}

.icon {
  margin-right: calc(unit / 2);
}

.policy {
  display: flex;
}

.inherited {
  color: secondary-color;
}

.disabled,
.disabled .inherited {
  color: disabled-color;
}

.caption {
  margin: 0;
  padding: 0;
}

.helpIcon {
  composes: helpIcon from '../CleanupPolicies.css';
}

.helpPopup {
  max-width: calc(unit * 40);
  padding: unit calc(unit * 2) calc(unit * 2);
}
