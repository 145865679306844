@value unit, font-size, line-height from '../../../ring-globals.css';

.title.title {
  margin-top: unit;
  margin-bottom: 0;

  font-size: font-size;
}

.warning {
  margin-bottom: calc(unit * 1.5);
}

.warning,
.description {
  line-height: line-height;
}
