@value unit '../../../../../ring-globals.css';

.button {
  display: block;

  width: 100%;

  padding: 0;

  text-align: left;

  border-radius: 0;
}
