@value unit, icon-secondary-color, icon-disabled-color, font-size-smaller, text-color, hover-background-color, line-height-lower from '../../../ring-globals.css';

.panel {
  margin-bottom: calc(unit * 4);
}

.heading,
.subheading {
  margin-right: unit;

  color: text-color;
}

.subheading {
  font-size: font-size-smaller;
  font-weight: normal;
  line-height: calc(2 * unit);
}

.header.header {
  margin-bottom: 0;

  color: inherit;

  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
}

.header-toggle {
  padding: calc(unit / 2) calc(2.75 * unit);

  cursor: pointer;

  outline: none;
}

.header-toggle:hover {
  background: hover-background-color;
}

.header-toggle.disabled {
  cursor: default;
}

.content {
  margin-top: calc(0.5 * unit);
  margin-bottom: calc(unit * 2);

  padding-top: 0;
  padding-bottom: 0;

  &.border {
    padding: calc(unit * 2);
    padding-top: calc(unit * 1.5);

    border: 1px solid #dfe5eb;

    border-radius: 3px;
  }
}

.chevronIcon {
  position: relative;

  display: inline;

  height: auto;

  margin-right: calc(unit / 2);
  padding: 0;

  line-height: line-height-lower;
}

.chevronIconWrapper.chevronIconWrapper {
  color: icon-secondary-color;
}

.disabled-icon {
  visibility: hidden;
}
