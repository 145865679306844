@value unit, font-size-smaller, secondary-color from '../../../ring-globals.css';

.links {
  display: flex;

  padding-top: unit;

  font-size: font-size-smaller;
}

.logLink {
  color: secondary-color;
}

.logIcon {
  margin-right: calc(unit / 2);

  vertical-align: -1px;

  color: var(--ring-icon-color);
}

.logLink:hover .logIcon {
  color: inherit;
}

.leftText {
  display: inline-block;

  padding-top: 1px;
}

.rightLinks {
  flex: 0 0 auto;

  margin-left: auto;

  & > * {
    margin-left: calc(unit * 1.5);
  }
}
