@value unit, ellipsis, warning-color from '../../../ring-globals.css';

.usernameWrapper {
  display: inline-flex;
  overflow: hidden;
}

.itsMe {
  margin: 0 calc(unit * -2) 0 auto;
}

.editLink {
  margin-left: auto;
}

.inline {
  display: inline-flex;
  flex-direction: column;
}

.inlineAuthor {
  display: flex;
  align-items: baseline;

  & .itsMe {
    padding: 0;
    padding-left: calc(unit * 1.5);
  }
}

.username {
  composes: ellipsis;
}

.userPopup {
  box-sizing: border-box;
  max-width: 600px;
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);

  line-height: calc(unit * 3);
}

.teamCityUser {
  display: flex;
  align-items: baseline;
}

.unknownUser {
  color: warning-color;
}

.author {
  display: flex;
  overflow: hidden;
}

.avatar {
  vertical-align: -6px;
}

.othersAvatar {
  transition: opacity var(--ring-fast-ease);

  opacity: 0.5;
}

.author:hover .othersAvatar {
  transition: none;

  opacity: 1;
}

.usersNote {
  margin-bottom: unit;
}

.usersAvatarWrapper {
  vertical-align: -5px;
}

.usersAvatar {
  vertical-align: baseline;
}
