@value unit, resetButton from '../../../ring-globals.css';

.button {
  composes: resetButton;

  display: block;

  width: 100%;
  margin: 0;

  cursor: pointer;
}

.heading {
  position: relative;

  cursor: pointer;
}

.asideButton {
  position: absolute;
  top: var(--absolute-child-top);
  right: 0;
  bottom: 0;
  left: 0;
}
