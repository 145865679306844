@value unit, font, secondary-color, heading-color, line-color, border-radius from '../../../ring-globals.css';

.builds {
  --column-width: minmax(0, max-content);
  --status-column-width: var(--column-width);
  --build-row-span: 3;
  --number-column: [start number-start] var(--column-width);
  --branch-column: [branch-start] var(--column-width);
  --status-column: [status-start] var(--status-column-width);
  --branch-status-columns: [branch-start status-start] var(--status-column-width);
  --changes-column: [changes-start] var(--column-width);
  --agent-column: [agent-start] var(--column-width);
  --changes-agent-columns: [changes-start agent-start] var(--column-width);
  --duration-column: [duration-start] auto;
  --tags-column: [tags-start] var(--column-width);
  --icons-column: [icons-start] var(--column-width);
  --duration-icons-columns: [duration-start icons-start] auto;

  composes: font;

  position: relative;

  justify-content: space-between;

  padding: 0 0 1px;
  grid-template-columns: var(--number-column) var(--branch-status-columns) var(--changes-agent-columns) var(--duration-icons-columns) [end];
  grid-column-gap: calc(unit * 2);
}

.withPath {
  --status-column-width: minmax(0, 100%);
}

.wrappedContainer {
  padding: 0 calc(unit * 2);
}

.wrapped {
  &::before {
    position: absolute;
    top: calc(var(--absolute-child-top) - 1px);
    right: calc(unit * -2 - 1px);
    bottom: 0;
    left: calc(unit * -2 - 1px);

    content: '';
    pointer-events: none;

    border: 1px solid line-color;
    border-radius: border-radius;
    grid-column: start / end;
    grid-row: var(--row) / span var(--row-span);
  }

  & .builds {
    margin-bottom: -2px;
  }
}

.withTags {
  --duration-icons-columns: [duration-start tags-start] var(--column-width) var(--icons-column);
}

.oneLine {
  --build-row-span: 2;
  --tags-icons-columns: var(--icons-column);
  --duration-icons-columns: var(--tags-icons-columns);
}

.oneLine.withBranch {
  --branch-status-columns: var(--branch-column) var(--status-column);
}

.oneLine.withAgent {
  --changes-agent-columns: var(--changes-column) var(--agent-column);
}

.oneLine.withDuration {
  --duration-icons-columns: var(--duration-column) var(--tags-icons-columns);
}

.oneLine.withTags {
  --tags-icons-columns: var(--tags-column) var(--icons-column);
}

.withOverlay {
  cursor: wait;

  opacity: 0.3;

  & > * {
    pointer-events: none;
  }
}

.placeholder {
  grid-column: start / end;

  composes: font;

  color: secondary-color;
}

.loader {
  grid-column: start / end;
}

.inlineLoader {
  grid-column: start / end;

  margin: unit 0;
}

.footer {
  min-height: line-height;

  margin-top: calc(unit);

  color: secondary-color;

  line-height: line-height;
}

.sectionTitle {
  margin-right: auto;
  margin-bottom: unit;

  color: heading-color;

  font-weight: bold;
}

.buttonContainer {
  display: block;
  grid-column: start / end;

  margin: -1px calc(unit * -2 - 1px) 0;

  border: 1px solid #dfe5eb;

  border-top-left-radius: border-radius;
  border-top-right-radius: border-radius;

  & > .show-queued-toggle {
    box-sizing: content-box;
    width: 100%;
    padding: calc(unit / 2) 0 calc(unit / 4);

    background-color: #f7f9fa;

    &:hover {
      background-color: #f7f9fa;
    }
  }
}

.oneLineMargin {
  height: unit;
  grid-column: start / end;
}

.hasParentGrid {
  display: contents;
}
