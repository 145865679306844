@value unit, lineClamp, icon-error-color, line-height-lowest from '../../../../ring-globals.css';
@value lineHeight: line-height-lowest;
@value maxLines: 3;

.icon {
  color: icon-error-color;
}

.description {
  composes: lineClamp;

  max-height: calc(lineHeight * maxLines + 1px);

  color: var(--ring-text-color);

  -webkit-line-clamp: maxLines;
}
