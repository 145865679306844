@value unit, link-color, secondary-color, font-size-smaller, font-size from '../../../../ring-globals.css';

.node {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex-wrap: nowrap;
}

.entityPath {
  line-height: 24px;
}

.entityPathLink {
  color: link-color;

  line-height: 24px;
}

.buildNumber {
  font-size: font-size;
  line-height: 24px;
}

.childrenCount {
  margin-left: unit;

  user-select: none;

  white-space: nowrap;

  color: secondary-color;

  font-size: font-size-smaller;
}

.separator {
  flex-shrink: 0;

  color: secondary-color;
}
