.container {
  padding-bottom: calc(var(--ring-unit) * 4 - 2px);
}

.title.title {
  margin-bottom: calc(var(--ring-unit) - 2px);
}

.changeVCS {
  padding: 0 calc(var(--ring-unit) * 1.5);

  color: var(--ring-secondary-color);
}

.githubIcon {
  margin-right: calc(var(--ring-unit) / 2);
}
