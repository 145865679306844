@value unit, secondary-color, font-smaller, line-height from '../../ring-globals.css';

.content {
  flex: 1 0 auto;
}

.emptyHistory {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: secondary-color;
}

.investigation {
  margin-bottom: calc(unit * 1.5);

  &:last-child {
    margin-bottom: 0;
  }
}

.investigationHeader {
  line-height: line-height;
}

.user {
  font-weight: bold;
}

.timestamp {
  composes: font-smaller;

  color: secondary-color;
}

.investigationContent {
  display: flex;
  flex-direction: column;
}

.resolveMethod {
  color: secondary-color;
}

.comment {
  overflow-wrap: break-word;

  white-space: normal;
}
