@value unit from '../../../../ring-globals.css';

.firstLine,
.secondLine {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.firstLine {
  position: relative;
  z-index: 3;
}

.filterControl {
  margin-right: unit;
  margin-bottom: unit;
}

.projectSelect {
  max-width: calc(unit * 50);
}
