@value unit, font-size-smaller, secondary-color, selected-background-color from '../../../ring-globals.css';

.detailsButton {
  color: secondary-color;
}

.anchorButton {
  height: unset;
}

.dropdown {
  flex: 0 0 auto;

  font-size: font-size-smaller;

  line-height: 12px;
}

.loader {
  padding: 0 0 unit calc(unit * 2);
}

.item.item {
  color: inherit;
  border-radius: 0;

  &:hover > * {
    border: none;
  }
}

.hoverItem {
  background-color: selected-background-color;
}

.icon,
.itemText::before {
  margin-right: calc(unit / 2);
}
