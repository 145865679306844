@value unit, ellipsis, secondary-color, line-height-lowest, icon-secondary-color from '../../../../../ring-globals.css';
@value descriptionHeight: line-height-lowest;

.header {
  display: flex;
  align-items: baseline;
}

.subheader {
  display: flex;
  flex-direction: row;

  margin-top: unit;
}

.title {
  display: flex;
  align-items: baseline;
}

.title.title {
  margin: 0;
  margin-right: auto;
  padding: 0;

  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

.datetime {
  margin-left: calc(unit / 2);

  white-space: nowrap;

  color: secondary-color;
}

.personalIcon {
  margin-right: calc(unit / 2);

  color: icon-secondary-color;
}

.comment {
  overflow-wrap: break-word;

  overflow: hidden;
}
