.item {
  display: flex;

  white-space: nowrap;
}

.content {
  flex: 1 1 auto;
}

.expandHandle {
  flex: 0 0 auto;
}
