@value unit from '../../../../ring-globals.css';

.row {
  margin-bottom: unit;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowHeading {
  font-weight: bold;
}
