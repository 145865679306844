@value unit, text-color from '../../../../ring-globals.css';

.edit-button {
  margin-bottom: calc(2 * unit);

  &:hover {
    text-decoration: none;

    color: text-color;
  }
}
