@value contentWidth from '../../../ring-globals.css';

.loader {
  max-width: contentWidth;
}

.morePopupItemClassName.morePopupItemClassName {
  padding-top: 0;
  padding-bottom: 0;
}
