@value unit from '../../../ring-globals.css';
@value enforced-color, inherited-color, own-color from '../Cleanup.css';

.ruleButton {
  display: block;
}

.addNewRule {
  margin-bottom: calc(unit / 2);
  margin-left: calc(unit * 3);
}

.description {
  max-width: calc(unit * 55);
}

.dialog {
  width: calc(unit * 130);
}

.summary {
  margin: 0;

  cursor: pointer;

  color: secondary-color;

  &:hover {
    opacity: 0.8;
  }

  &:not(.onlyInheritedRules) {
    & .own {
      text-decoration: underline;

      color: own-color;

      font-weight: bold;
    }
  }
}

.table {
  padding-bottom: unit;
}

.inheritedToggle {
  margin-bottom: calc(unit * 4);
}
