@value unit, contentWidth, font-smaller-lower, secondary-color from '../../../ring-globals.css';

.page,
.pageLoader {
  max-width: contentWidth;
}

.header {
  margin-bottom: calc(unit * 3);
}

.header .heading {
  overflow: hidden;

  margin: 0;
  margin-bottom: calc(unit * 2);

  word-break: break-word;

  @nest &:hover .copyTestNameIcon {
    opacity: 1;
  }
}

.headingTestName {
  margin-right: 30px;
}

.copyTestNameIconWrapper {
  margin-left: -30px;
}

.copyTestNameIcon {
  height: 28px;

  opacity: 0;

  &:focus {
    opacity: 1;
  }
}

.subHeading {
  composes: font-smaller-lower;

  color: secondary-color;

  font-weight: normal;
}

.branchFilter,
.investigationMuteButtons {
  margin-right: unit;
}
