@value unit, main-color from '../../ring-globals.css';

.placeholder,
.wrapper {
  height: 32px;

  background-color: var(--ring-main-color);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 960px;

  height: calc(unit * 4);

  color: white;

  background: main-color;

  font-size: 14px;
}

.controls {
  flex-shrink: 0;
}

.more {
  margin-left: unit;

  font-size: 14px;
}

.more.more,
.switch {
  margin-right: unit;

  color: white;
  background: none;
}

.description {
  overflow: hidden;

  margin-left: auto;
  padding-left: calc(unit * 2);

  white-space: nowrap;
  text-overflow: ellipsis;

  line-height: 1em;
}

.feedback {
  flex-shrink: 0;

  margin-right: calc(unit * 4);
  margin-left: auto;

  color: white;

  font-size: 12px;

  &:hover {
    color: white;
  }
}

.feedbackIcon {
  padding-right: calc(unit / 2);
  padding-left: calc(unit);

  color: white;

  &:hover {
    color: white;
  }
}
