@value unit, line-height, hover-background-color, success-color, secondary-color, icon-secondary-color, ellipsis, font-size-smaller, main-color from '../../../ring-globals.css';

.heading {
  margin-bottom: 2px;

  font-weight: bold;
}

.outerItemContainer {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);

  line-height: 24px;

  &:hover {
    background-color: hover-background-color;
  }
}

.innerItemContainer {
  display: flex;
}

.changeTypeCol {
  min-width: 70px;

  text-transform: capitalize;

  font-size: font-size-smaller;

  &.added {
    color: success-color;
  }

  &.edited {
    color: main-color;
  }

  &.removed {
    color: secondary-color;
  }
}

.nameCol {
  composes: ellipsis;

  display: flex;
  flex: 1;

  margin-right: calc(unit * 2);
}

.icon {
  margin-right: unit;

  color: icon-secondary-color;
}

.name {
  overflow: hidden;
}

.actionsCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.externalViewer {
  margin-right: calc(unit * 2);
}

.actionsAnchor {
  height: unset;
  padding: 0;

  line-height: line-height;
}

.smallPadding {
  & .item {
    margin: 0 calc(unit * -2);
    padding: 0 calc(unit * 2);
  }

  & .outerItemContainer {
    margin: 0 calc(unit * -2);
    padding: 0 calc(unit * 2);
  }
}
