@value unit, line-color, secondary-color, font-size-smaller, resetButton, line-height-lowest from '../../../../../../ring-globals.css';
@value withSuccessColumn, withFailureColumn, withMutedColumn, withIgnoredColumn from '../TestScopesView.css';

.tableHeader {
  position: relative;

  display: contents;
}

.tableHeader::before {
  position: absolute;
  right: 0;
  bottom: var(--absolute-child-top);
  left: 0;

  padding-bottom: unit;

  content: '';

  border-bottom: 1px solid line-color;

  grid-column: start / end;
  grid-row: var(--row) / span 1;
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.nameColumn {
  display: flex;

  grid-column-start: name-start;
  grid-row-start: var(--row);
}

.durationColumn {
  grid-column-start: duration-start;
}

.countColumn {
  grid-column-start: count-start;
}

.successColumn {
  grid-column-start: success-start;
}

.failureColumn {
  grid-column-start: failure-start;
}

.mutedColumn {
  grid-column-start: muted-start;
}

.ignoredColumn {
  grid-column-start: ignored-start;
}

.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  grid-row-start: var(--row);

  text-align: right;
}

.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  visibility: hidden;
}

.nameColumn,
.durationColumn,
.countColumn,
.successColumn,
.failureColumn,
.mutedColumn,
.ignoredColumn {
  overflow: hidden;

  padding-right: calc(unit * 2);
  padding-bottom: unit;

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lowest;

  &:last-child {
    padding-right: 0;
  }
}

.withSuccessColumn .successColumn,
.withFailureColumn .failureColumn,
.withMutedColumn .mutedColumn,
.withIgnoredColumn .ignoredColumn {
  visibility: visible;
}
