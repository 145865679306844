@value unit, ellipsis, line-color, secondary-color, border-radius, border-radius-small, font-smaller, font-size-smaller, line-height-lower from '../../../../ring-globals.css';

.build {
  display: contents;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.not-comment-wrapper {
  display: contents;

  & > * {
    position: relative;

    box-sizing: border-box;
    margin-bottom: -1px;
  }
}

.build::after {
  position: absolute;
  top: calc(var(--absolute-child-top) - 1px);
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  pointer-events: none;

  border-top: 1px solid line-color;
  border-bottom: 1px solid line-color;

  grid-column: start / end;
  grid-row: var(--row) / span var(--build-row-span);
}

.dottedBorders:not(.queued)::after {
  border-top-style: dotted;
  border-bottom-style: dotted;
}

.wrapped::after {
  right: calc(unit * -2);
  left: calc(unit * -2);
}

.comment {
  margin-top: 0;

  &.separate-from-top {
    margin-top: calc(unit);
  }
}

.number {
  grid-column-start: number-start;
  grid-row-start: var(--row);

  margin-top: 5px;

  line-height: 20px;
}

.inHierarchy .number {
  margin-left: var(--offset);
}

.branchPath {
  composes: ellipsis;

  display: flex;
  align-items: baseline;

  margin-top: 0;

  grid-column-start: branch-start;
}

.status {
  grid-column-start: status-start;

  overflow: hidden;

  margin: -2px -2px -3px;

  & > * {
    margin: 2px;
  }
}

.downloadedDeliveredArtifacts {
  grid-column: changes-start / end;
  grid-row-start: calc(var(--row) + 1);

  height: 33px;

  line-height: 30px;
}

.changes {
  width: calc(unit * 26);
  grid-column-start: changes-start;
  grid-row-start: var(--row);
}

.agent {
  grid-column-start: agent-start;

  max-width: 100%;
}

.durationIcons {
  display: contents;

  text-align: right;
}

.duration {
  position: relative;

  min-width: calc(unit * 14);

  height: calc(unit * 2);
  grid-column: duration-start / end;
  grid-row-start: var(--row);
}

.exactDuration {
  min-width: calc(unit * 20);
}

.icons {
  grid-column-start: icons-start;

  margin: 3px calc(unit * -1) 5px;

  &::before {
    content: '\200B'; /* zero-width space */

    line-height: calc(unit * 3);
  }
}

.tags {
  grid-column-start: tags-start;
}

.tag {
  max-width: calc(100% - unit);
}

.branch {
  box-sizing: border-box;
  max-width: 40%;
  margin-top: 1px;
  margin-right: unit;
}

.path {
  flex: 1;

  margin: -3px;
}

.branch:last-child {
  max-width: 100%;
  margin-right: 0;
}

.stop {
  margin-right: calc(unit * -1);
}

.without-path .noBranch {
  display: none;
}

.triggeredBy {
  margin: -1px 0;

  text-align: left;
  white-space: normal;

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lower;
}

.hideBorders::after {
  display: none;
}

.description {
  position: relative;

  min-width: 0;
  grid-column: status-start / end;

  & > :not(:empty) {
    margin: -1px 0 9px;
  }
}

.compactDescription {
  grid-row: calc(var(--row) + 1) / span 2;
  grid-column-end: changes-start;

  & > :not(:empty) {
    margin-bottom: unit;
  }
}

.emptyLine {
  display: none;
}

.oneLine {
  & .not-comment-wrapper > * {
    white-space: nowrap;
  }

  & .status {
    display: inline-flex;
    align-items: baseline;
  }

  & .duration {
    grid-column-end: span 1;
  }

  & .emptyLine {
    display: block;
    grid-column: start / end;
  }
}

.oneLinePaddings {
  & .not-comment-wrapper > * {
    margin-top: 0;
  }

  & .status {
    margin-top: -2px;
  }

  & .icons {
    margin-top: -1px;
    margin-bottom: 1px;
  }

  & .description > :not(:empty) {
    margin: -1px 0 5px;
  }

  & .triggeredBy {
    margin-top: -2px;
  }
}

/* stylelint-disable-next-line selector-max-specificity */
.wrappedContainer:first-child .build::after {
  border-top: none;
}

/* stylelint-disable-next-line selector-max-specificity */
.wrappedContainer:last-child .build::after {
  border-bottom: none;
}

.placeholder {
  box-sizing: border-box;

  border-radius: 4px;

  background-color: #ecf0f3;
}

@keyframes placeholder {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.7;
  }
}

.placeholder.animated {
  transform: translateZ(0);
  animation: placeholder 1s linear infinite;
  animation-direction: alternate-reverse;
}

.placeholder.placeholder {
  min-width: calc(unit * 10);
  height: calc(unit * 2);
  margin-top: unit;
}

.oneLine .placeholder {
  margin: calc(unit / 2) 0;
}

.icons.placeholder {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 0;

  &::before {
    content: none;
  }
}

.oneLine .icons.placeholder {
  margin-bottom: calc(unit / 2);
}
