@value unit from '../../../ring-globals.css';

.container {
  margin-bottom: calc(unit / 2);
}

.embedded {
  max-width: calc(unit * 30);
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 1.5);

  border-bottom: 1px solid #dfe5eb;
}

.icon {
  margin-right: unit;
}
