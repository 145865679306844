@value unit, disabled-color from '../../../ring-globals.css';

.sidebarSearch {
  position: relative;

  & button {
    padding-right: calc(unit);
  }
}

.empty:not(:focus-within)::after {
  position: absolute;
  top: 0;
  right: calc(unit * 2);

  content: 'Q';

  color: disabled-color;

  line-height: calc(unit * 3);
}

.wrapper {
  padding: unit calc(2 * unit) 0 30px;
}

.edit {
  &.empty::after {
    content: none;
  }
}

.searchInputHint {
  margin-top: calc(unit / 2);
}
