@value contentWidth, unit, line-color, secondary-color, error-color, font-size-smaller, line-height-lowest, link-hover-color from '../../../ring-globals.css';
@value copyrightWidth: calc(32 * unit);
@value sideColumnWidth: calc(100% / 2 - copyrightWidth / 2);

.footer {
  max-width: calc((unit * 8) + contentWidth);
  min-height: calc(7 * unit);

  margin-top: calc(unit * 4);

  font-size: font-size-smaller;
  line-height: line-height-lowest;

  & a {
    color: inherit;
  }

  &.invisible {
    display: none;
  }
}

.content {
  position: relative;

  margin: 0 calc(4 * unit);
  padding: unit 0;

  border-top: 1px solid line-color;
}

.version {
  width: sideColumnWidth;

  color: secondary-color;
}

.copyright {
  position: absolute;
  top: unit;
  right: 0;
  left: 0;

  width: copyrightWidth;

  margin-right: auto;
  margin-left: auto;

  text-align: center;
}

.links {
  position: absolute;
  top: unit;
  right: 0;

  width: sideColumnWidth;

  text-align: right;

  color: secondary-color;

  & a {
    margin-left: unit;

    white-space: nowrap;
  }
}

.extesnionsLinks {
  & a,
  & span {
    margin: 0;
  }

  & a {
    margin-left: unit;

    &:hover {
      color: link-hover-color;
    }
  }
}

.licenseExpiration {
  color: error-color;
}
