@value unit, text-color, line-height from '../../../../ring-globals.css';

.more-button {
  margin-left: unit;
}

.more-button-popup {
  margin-top: calc(0.5 * unit);
}

.dropdown {
  line-height: line-height;
}

.anchor {
  color: text-color;
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}

.placeId {
  display: inline-block;
}
