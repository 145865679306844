@value secondary-color from '../../../ring-globals.css';

.buildPath {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.separator {
  flex-shrink: 0;

  color: secondary-color;
}
