@value unit, font-smaller, text-color, main-color, secondary-color, fast-ease from '../../../ring-globals.css';

.build-number {
  composes: font-smaller;
  composes: wrapper from '../StarBuild/StarBuild.css';
  composes: link from '../LinkWithIcon/LinkWithIcon.css';

  position: relative;

  color: text-color;
}

.my-personal {
  font-weight: bold;
}

.personal,
.canceled {
  color: secondary-color;
}

.canceled,
.canceled:hover {
  text-decoration: line-through;
}

.text {
  composes: text from '../LinkWithIcon/LinkWithIcon.css';
}

.star-icon {
  padding: 0 calc(unit / 2);
}

.number-with-comment {
  min-width: 0;
}

.comment,
.warning {
  margin-left: calc(unit / 2);
}

.link {
  width: 100%;
  min-width: 0;
}

.moveToTop.moveToTop {
  margin-left: calc(unit * -1);
  padding-right: 3px;
}
