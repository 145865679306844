@value error-color from '../../../../ring-globals.css';

.botton {
  position: absolute;
  right: 0;

  color: error-color;
}

.confirm {
  z-index: 200;
}
