@value unit from '../../../../ring-globals.css';

.agent-build-types-tab {
  position: relative;
}

.search {
  position: absolute;
  top: 0;
  right: calc(-2 * unit);
}

.section {
  margin: calc(4 * unit) calc(-4 * unit) 0 calc(-4 * unit);
  padding: 0 calc(4 * unit);
}

.section-header {
  height: calc(3 * unit);
}

.loader {
  margin-left: calc(4 * unit);
}

.assignment-action {
  position: absolute;
  right: 0;

  color: link-color;
}
