@value unit from '../../../../ring-globals.css';

.anchor {
  display: flex;
}

.icon {
  display: block;
}

.name {
  white-space: nowrap;
}

.icon,
.name {
  margin-right: unit;
}

.transformed-comment {
  overflow: hidden;
  flex-shrink: 1;

  white-space: nowrap;

  text-overflow: ellipsis;
}

.transformed-comment br {
  display: inline;
  clear: none;

  content: ' ';
}

.popup {
  max-width: calc(40 * unit);
  padding: unit;
}
