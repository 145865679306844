@value unit, secondary-color, font-size-smaller from '../../../../../ring-globals.css';

.description {
  max-width: calc(unit * 65);

  margin-top: -6px;
  margin-bottom: calc(unit / 2);
  margin-left: calc(unit * 3);

  color: secondary-color;

  font-size: font-size-smaller;
}

.portConfig {
  margin-top: calc(unit * 1.5);
  margin-bottom: calc(unit * 1.5);
}

.urlsInfo {
  margin-top: unit;
}
