@value unit, line-color from '../../../../../ring-globals.css';

.wrapper {
  padding: calc(unit * 2) 0;
}

.changeInfo {
  margin: unit calc(unit * -4) 0;
  padding: calc(unit * 2) calc(unit * 4) 0;

  box-shadow: 0 -1px line-color;
}

.smallPadding {
  & .changeInfo {
    margin: unit calc(unit * -2) 0;
    padding: calc(unit * 2) calc(unit * 2) 0;
  }
}

.showFilesMode {
  &.wrapper {
    padding-top: 0;

    padding-bottom: calc((unit * 2));

    border-bottom: 1px solid line-color;
  }
}
