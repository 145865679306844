@value unit, font-smaller-lower, secondary-color, link-hover-color from '../../../../../ring-globals.css';

.container {
  composes: font-smaller-lower;

  white-space: pre-wrap;

  color: secondary-color;
}

.activate {
  margin-left: unit;

  color: #008eff;

  &.line-break {
    display: block;

    margin-left: 0;
  }
}

.icon {
  margin-right: calc(unit / 2);
}

.dropwdown {
  margin-left: unit;

  white-space: nowrap;

  & button {
    padding-left: 0;
  }
}

.dropwdown-active {
  color: link-hover-color;
}

.popup {
  max-width: 600px;
  padding: unit calc(2 * unit) calc(2 * unit);

  color: inherit;
}

.chevron {
  margin-left: 2px;
}
