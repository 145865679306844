@value unit, secondary-color, selected-background-color, main-color, link-color from '../../../ring-globals.css';

.container {
  --runner-item-width: 288px;

  margin-top: calc(unit * 3);
}

.groupItems {
  position: relative;
  left: -8px;

  display: grid;

  padding-bottom: calc(unit * 5);

  grid-template-columns: repeat(auto-fit, 288px);
  grid-auto-columns: 288px;
  grid-auto-rows: auto;
}

.hidden {
  display: none;
}

.description,
.button {
  margin-top: 8px;

  font-size: 12px;
}

.item {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: 8px;
  padding: 8px 16px 12px 8px;

  border-radius: 4px;

  background: rgba(0, 0, 0, 0.005);

  &:hover {
    display: flex;

    cursor: pointer;

    background: selected-background-color;

    & .button {
      color: white;
      border-color: main-color;
      background-color: main-color;
      box-shadow: none;
    }
  }
}

.deprecated {
  color: secondary-color;
}

.header {
  display: flex;
}

.icon {
  height: calc(unit * 2.5);
  margin-right: 8px;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.description {
  color: var(--ring-secondary-color);

  &.selected {
    max-width: 60%;
    margin-top: 0;
  }
}

.selectedRunner {
  display: flex;
  align-items: baseline;
}

.reselect-button {
  width: 100px;
  margin: 0;
  padding: 0;

  color: link-color;
  border: none;
  background: none;

  font-size: 12px;
}

.filter {
  max-width: calc(unit * 40);
  margin-top: unit;
}

.notFound {
  position: relative;

  padding: unit 0 calc(unit * 3);

  color: secondary-color;
}
