.title.title {
  margin: calc(var(--ring-unit) * 4) 0 calc(var(--ring-unit) * 2);
  padding: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.runs {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content) minmax(0, max-content);
  grid-column-gap: calc(var(--ring-unit) * 2);

  justify-content: space-between;
}

.columnHeader {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.duration {
  text-align: end;
}

.columnHeadersUnderline {
  margin-top: var(--ring-unit);

  border-bottom: 1px solid var(--ring-line-color);
  grid-column: 1/-1;
}
