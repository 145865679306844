@value unit, main-hover-color, link-hover-color from '../../../../ring-globals.css';

.wrapper {
  position: relative;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.title {
  letter-spacing: 2px;
}

.popup {
  padding: calc(unit);
}

.popup-header {
  margin-top: calc(2 * unit);

  font-size: calc(3 * unit);
}
