@value unit, link-hover-color from '../../../../../ring-globals.css';

.code {
  margin: 0;

  border: 1px solid transparent;
}

.row:hover .code {
  border-color: link-hover-color;
}

.wrap {
  position: relative;
}
