@value unit, line-height, icon-secondary-color, main-color, font-size-smaller, font-size, text-color, secondary-color, line-color, popup-shadow-color from '../../../../ring-globals.css';
@value focusableWrapper from '../../../common/ListItem/ListItem.css';
@value link from '../../../common/BuildNumber/BuildNumber.css';

.panel {
  z-index: 1;

  margin: 1px calc((unit * -4)) 0;
  padding: 0 calc((unit * 4)) calc(unit * 2);

  box-shadow:
    0 0 0 1px line-color,
    0 2px 8px popup-shadow-color;
}

.button {
  composes: focusableWrapper;
}

.item {
  composes: focusableWrapper;

  position: relative;

  display: block;

  margin: 1px calc(unit * -4) 0;
}

.row {
  display: flex;
  align-items: baseline;
  flex-direction: row;

  padding: 0;

  & > * {
    margin-top: 0;
  }
}

.arrow {
  position: absolute;

  top: 5px;
  right: calc(unit * -4 + 10px);
  left: auto;

  padding: 0;

  transition: opacity ease;

  pointer-events: none;

  opacity: 0;
  color: icon-secondary-color;

  line-height: normal;

  &.advancedRowArrow {
    top: 8px;
  }

  @nest .item:hover ~ &,
  .button:focus ~ &,
  .button[data-focus-visible-added] ~ & {
    transition: none;

    opacity: 1;
  }

  @nest .button:active ~ & {
    transition: none;

    opacity: 1;
    color: main-color;
  }
}

.smallPadding {
  &.panel {
    margin: 1px calc((unit * -2)) 0;
    padding: 0 calc((unit * 2)) calc(unit * 2);
  }

  & .item {
    margin: 1px calc(unit * -2) 0;
    padding: 0 calc(unit * 3);
  }

  & .arrow {
    right: calc(unit * -4.5);
  }
}

.defaultRow {
  line-height: 24px;

  &::before {
    height: 24px;
  }
}

.advancedRow {
  min-height: 31px;

  &::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .row {
    padding-bottom: 6px;

    line-height: line-height;

    & > * {
      margin-top: 5px;
    }
  }
}

.pathCol {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex: 1 1 auto;
}

.entityPath {
  white-space: nowrap;
}

.filesCol {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;

  width: calc(unit * 7);

  text-align: right;

  white-space: nowrap;

  font-size: font-size-smaller;
}

.pathCol,
.filesCol {
  padding-left: 0;
}

.expandableLines {
  & .filesCol {
    width: 70px;
  }
}

.separator {
  flex-shrink: 0;

  color: secondary-color;
}

.buildNumber {
  margin-right: unit;
}

.buildNumber .link {
  color: text-color;

  font-size: font-size;
}
