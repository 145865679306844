@value unit, main-color, icon-hover-color, content-background-color, overlay-z-index, icon-color, fast-ease from '../../../../ring-globals.css';
@value verticalCompensation: 2px;

.downloadIcon {
  margin-right: calc(unit / 2);

  transition: color fast-ease;

  color: icon-color;
}

.downloadLink {
  z-index: overlay-z-index;

  padding: 0 10px verticalCompensation;

  background-color: content-background-color;

  line-height: calc(unit * 3 - verticalCompensation);

  &:hover .downloadIcon,
  &:focus .downloadIcon,
  &[data-focus-visible-added] .downloadIcon {
    transition: none;

    color: icon-hover-color;
  }

  &:active .downloadIcon {
    transition: none;

    color: main-color;
  }
}
