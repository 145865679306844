@value unit, secondary-color, icon-success-color from '../../../../ring-globals.css';

.additionalInfoLink {
  margin-right: calc(unit * 1.5);
}

.ignoredIcon > *,
.mutedIcon > * {
  color: secondary-color;
}

.passedIcon {
  color: icon-success-color;
}
