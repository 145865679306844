@value unit, secondary-color from '../../../ring-globals.css';

.agents {
  padding: 0 unit 1px;
}

.placeholder {
  color: secondary-color;
}

.loader {
  margin: -1px 0 0 unit;
}
