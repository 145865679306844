@value unit, secondary-color, font-size-smaller from '../../../../ring-globals.css';

.redirectTitle {
  composes: title from '../HttpsConfigurationPage.css';
}

.info {
  display: flex;
  column-gap: calc(unit * 7);

  margin-bottom: calc(unit * 1.5);
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: calc(unit * 1.5);
}

.caption {
  margin-top: unit;

  color: secondary-color;

  font-size: font-size-smaller;
}
