@value unit, text-color, icon-color, icon-error-color, icon-success-color from '../../../../../../ring-globals.css';

.filter {
  display: flex;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: wrap;

  &:last-child {
    margin-right: 0;
  }
}

.stateButton.active .ignoredIcon,
.stateButton:hover .ignoredIcon,
.stateButton.active .mutedIcon,
.stateButton:hover .mutedIcon {
  color: text-color;
}

.stateButton.active .failedIcon,
.stateButton:hover .failedIcon {
  color: icon-error-color;
}

.stateButton.active .passedIcon,
.stateButton:hover .passedIcon {
  color: icon-success-color;
}

.stateIcon {
  transition: color fast-ease;

  color: icon-color;
}

.filterLabel {
  margin-bottom: calc(unit * 2);
  margin-left: calc(unit / 2);
}
