@value unit, hover-background-color, sidebar-background-color, font-size-smaller, font-size, main-color from '../../../../../ring-globals.css';

.row {
  flex-flow: row nowrap;

  margin: 0 calc(unit * -4) unit;

  padding: 1px calc(unit * 4) 3px calc(unit * 4);

  &.changed {
    background-color: sidebar-background-color;
    box-shadow: inset 3px 0 main-color;
  }

  &:hover,
  &.changed:hover {
    background-color: hover-background-color;
  }

  & > div {
    margin: 0;
  }
}

.buildComparerCol,
.buildNumberCol {
  width: calc(8 * unit);
  padding-right: unit;
  padding-left: unit;

  font-size: font-size-smaller;
}

.buildPath {
  position: relative;
  top: 1px;
}

.nameCol {
  flex: 1;

  min-width: 0;
  padding-right: unit;
  overflow-wrap: break-word;
}

.buildNumber {
  font-size: inherit;
  line-height: inherit;
}

.link {
  max-width: calc(100% - unit * 2);
  margin: 2px 0;

  color: black;

  line-height: 22px;
}

.caption {
  width: 100%;
  max-width: 100%;
  padding-right: calc(unit / 2);

  font-size: 13px;
}
