@value unit, font-smaller-lower, line-color, font-size-smaller, secondary-color from '../../../ring-globals.css';

.sidebar {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.footer {
  height: calc(5 * unit);

  border-top: 1px solid line-color;

  font-size: font-size-smaller;
}

.projectsTree,
.arrowKeyStepper {
  height: 100%;
}

.projectsTree,
.list {
  outline: none;
}

.content {
  height: 100%;
  margin-top: calc(unit * 1.5);
}

.loader {
  margin-top: 13px;
  padding-left: calc(unit * 3.5);

  line-height: calc(unit * 3);

  &:first-child {
    margin-top: 0;
  }
}

.loaderText {
  margin-left: 2px;
}

.placeholder {
  composes: font-smaller-lower;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  flex-grow: 1;

  margin: calc(unit * 2) 0 0 calc(unit * 4);

  color: secondary-color;
}

.createButton {
  margin-top: unit;
}
