@value unit, icon-warning-color, hover-background-color from '../../../../../ring-globals.css';

.grid {
  padding: 0 calc(unit * 2);
}

.path {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  grid-column: start / end;

  min-width: 0;

  margin: 0 calc(unit * -2);
  padding-top: 6px;
  padding-bottom: 6px;

  &::after {
    position: absolute;

    z-index: -1;
    top: 0;
    right: calc(unit * -6);
    bottom: 0;
    left: calc(unit * -6);

    content: '';

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }

  &:hover::after {
    background-color: hover-background-color;
  }
}

.build + .pathRowGroup .path {
  margin-top: calc(unit * 2);

  @nest &::after {
    top: calc(unit * 2);
  }
}

.branch {
  margin-right: unit;

  white-space: nowrap;
}

.triggered {
  display: inline;

  margin-right: unit;
}

.entityPath {
  flex: 1;
}

.deploy {
  display: flex;
  align-items: baseline;

  margin-left: auto;
  padding-left: calc(unit * 2);
}

.warningIcon {
  color: icon-warning-color;
}

.rollbackPopup {
  padding: unit calc(unit * 2) calc(unit * 2);
}
