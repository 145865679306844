@value titles from '@jetbrains/ring-ui/components/tabs/tabs.css';
@value popupMinHeight: calc(var(--ring-unit) * 48);

.popup {
  overflow: auto;

  min-height: popupMinHeight;
  padding: 0 calc(var(--ring-unit) * 4) calc(var(--ring-unit) * 4) calc(var(--ring-unit) * 4);

  border: 1px solid var(--ring-line-color);
  border-radius: var(--ring-border-radius);
  background-color: var(--ring-sidebar-background-color);

  box-shadow: var(--ring-popup-shadow);
}

.gamePopup {
  background-color: #faf8ef;
}

.tabs {
  display: flex;
  flex-direction: column;
}

.tabs .titles {
  align-self: center;
}

.game {
  height: 1221px;
}
