.container {
  display: block;

  margin: 0 calc(var(--ring-unit) * -4);
  padding: calc(var(--ring-unit) / 4) calc(var(--ring-unit) * 4);
}

.container:hover {
  background-color: var(--ring-hover-background-color);
}
