@value unit, success-color, error-color, secondary-color from '../../../../../../ring-globals.css';

.successStatus {
  color: success-color;
}

.failedStatus {
  color: error-color;
}

.unknownStatus {
  color: secondary-color;
}

.muted {
  color: secondary-color;
}

.icon {
  margin-left: calc(unit / 2);
  padding: 0;

  color: secondary-color;
}
