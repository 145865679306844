@value unit, link-hover-color, line-height from '../../../../ring-globals.css';
@value lineHeight: line-height;

.differenceMark {
  position: absolute;
  top: calc((lineHeight - unit) / 2);
  left: calc(unit * -1.5);

  display: block;

  width: unit;
  height: unit;

  border-radius: unit;

  background-color: link-hover-color;
}
