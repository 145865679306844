@value unit, error-color, warning-color, secondary-color, font-size-smaller, font-family-monospace, icon-hover-color from '../../../../ring-globals.css';

.message {
  position: relative;

  display: inline-block;

  overflow: hidden;

  padding-left: calc(unit * 2);

  cursor: text;

  user-select: text;

  white-space: pre;
  word-break: break-all;
  line-break: anywhere;
}

.treeMessage {
  padding-left: calc(unit * 3.5);
}

.text {
  position: relative;

  display: inline-block;
}

.searchHighlighiting {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  user-select: none;

  pointer-events: none;

  color: transparent;
}

.breakLines {
  white-space: pre-wrap;
}

.message.error,
.message.error:hover {
  color: error-color;
}

.message.warning,
.message.warning:hover {
  color: warning-color;
}

.message.verbose,
.message.verbose:hover {
  color: secondary-color;
}

.chevronButton {
  position: absolute;
  top: -2px;
  left: calc(unit - 1px);

  padding: 0 0 0 5px;

  user-select: none;

  font-family: font-family-monospace;
  font-size: font-size-smaller;

  & .chevronIcon {
    transition: none;

    color: secondary-color;
  }

  &:hover {
    & .chevronIcon {
      color: icon-hover-color;
    }
  }
}

.loaderWrapper {
  position: absolute;
  top: 1px;
  left: calc(unit + 2px);
}

.loader {
  display: inline-block;
}

.duration {
  user-select: none;

  color: secondary-color;
}
