@value unit from '../../../../../ring-globals.css';

.title {
  composes: title from '../../../../common/ServiceMessage/ServiceMessage.css';

  color: white;

  font-size: 13px;
}

.controls {
  composes: title from '../../../../common/ServiceMessage/ServiceMessage.css';
}

.text {
  margin-top: calc(unit / 2);
}

.description {
  margin: 0;
}
