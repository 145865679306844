@import '@jetbrains/ring-ui/components/global/variables.css';

.topbar {
  margin: 10px 0;

  padding-bottom: 10px;

  border-bottom: 1px solid #f0f0f0;
}

.metricsLink {
  float: right;
}

.metricsTable {
  display: table;

  table-layout: fixed;
}

.metric__description {
  display: table-cell;

  font-weight: 600;
}

.metricRow {
  display: table-row;
}

.metricRowExperimental {
  opacity: 0.6;
}

.metricRow > th {
  text-align: left;

  background-color: var(--ring-sidebar-background-color);
}

.headerTagsValues {
  display: flex;
  justify-content: space-around;

  width: 100%;
}

.metricRow:hover {
  background-color: var(--ring-sidebar-background-color);
}

.metricRow > * {
  overflow-y: auto;

  box-sizing: border-box;

  width: 33%;

  padding: 10px;
}

.metric {
  display: flex;
  justify-content: space-between;
}

.metric__name {
  display: table-cell;
}

.metric__nameTooltip {
  padding: 5px 10px;
}

.metric__records {
  display: table-cell;

  width: 30%;
}

.metric__record {
  display: flex;
}

.metricTags {
  overflow-y: auto;

  min-width: 70%;
  padding-right: 10px;
  padding-bottom: 20px;
}

.metricValues {
  padding-right: 5px;
}

.metricValue {
  white-space: nowrap;

  font-weight: 600;
}
