@value unit, line-color, main-color, hover-background-color, font from '../../../ring-globals.css';

.listItem {
  composes: font;

  position: relative;

  box-sizing: border-box;

  margin: 1px calc(unit * -4) 0;
  padding: 0 calc(unit * 5);

  line-height: calc(unit * 3);

  &::before {
    position: absolute;
    z-index: -2;
    top: -1px;
    right: 0;
    bottom: -1px;
    left: 0;

    content: '';

    opacity: 0;
    background-color: hover-background-color;
  }
}

.focusableWrapper:focus,
.focusableWrapper[data-focus-visible-added] {
  outline: none;
}

/* stylelint-disable-next-line selector-max-specificity */
.focusableWrapper:focus .listItem::before,
.focusableWrapper[data-focus-visible-added] .listItem::before,
.focusableWrapper:focus + .listItem::before,
.focusableWrapper[data-focus-visible-added] + .listItem::before {
  opacity: 1;
  background-color: transparent;
  box-shadow: inset 2px 0 main-color;
}

/* stylelint-disable-next-line selector-max-specificity */
.highlightHover:hover::before,
.focusableWrapper:focus .highlightHover::before,
.focusableWrapper[data-focus-visible-added] .highlightHover::before,
.focusableWrapper:focus + .highlightHover::before,
.focusableWrapper[data-focus-visible-added] + .highlightHover::before {
  opacity: 1;
  background-color: hover-background-color;
}

.row {
  align-items: baseline;
  flex-wrap: nowrap;

  padding-bottom: 10px;

  box-shadow:
    0 1px line-color,
    0 -1px line-color;
}

.hideTopBorder .row {
  box-shadow: 0 1px line-color;
}

.hideBorders {
  margin-top: 0;

  & .row {
    box-shadow: none;
  }

  &::before {
    top: 0;
    bottom: 0;
  }
}

.row > * {
  box-sizing: border-box;
  margin: 6px 0 0;
  padding: 0 unit;
}

.row > :first-child {
  padding-left: 0;
}

.row > :last-child {
  padding-right: 0;
}
