@value unit, font-size-smaller, hover-background-color, line-color from '../../../ring-globals.css';

.container {
  display: flex;

  align-items: center;
  flex: 0 0 auto;

  height: calc(5 * unit);

  border-top: 1px solid line-color;

  font-size: font-size-smaller;
}

.searchMode {
  justify-content: flex-end;
}

.archiveToggle {
  margin: 0 unit;
}

.toggleCollapseIcon {
  position: relative;

  transition: transform 400ms ease-in-out;
  transform: scale(1, 1);
  vertical-align: -1px;

  color: var(--ring-text-color);
}

.uncollapseIcon {
  transform: scale(-1, 1);
}

.toggleCollapseButton {
  height: 38px;

  padding-right: calc(2.5 * unit);
  padding-bottom: unit;
  padding-left: calc(2.5 * unit);

  &:hover,
  &:focus,
  &[data-focus-visible-added],
  &:active {
    & .toggleCollapseIcon {
      color: inherit;
    }
  }
}

.toggleCollapseButton.toggleCollapseButton {
  color: var(--ring-text-color);

  &,
  &:hover {
    transition: padding-left 200ms ease-in-out;
  }
}

.uncollapseButton {
  display: flex;
  flex-direction: column-reverse;

  width: 100%;
  height: 100%;

  padding-right: calc(unit + 1px);
  padding-bottom: calc(unit + 3px);
  padding-left: calc(unit + 2px);

  white-space: nowrap;

  border-radius: 0;

  &:hover.uncollapseButton {
    background-color: hover-background-color;
  }
}

.collapseText {
  visibility: visible;

  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
  transition-property: opacity, visibility;

  opacity: 1;
}

.rotatedBar {
  composes: rotatedBar from '../SidebarSearch/CollapsedSidebarSearch/CollapsedSidebarSearch.css';

  top: auto;

  bottom: 0;

  padding-left: calc(unit / 2);

  transform: rotate(-90deg);

  line-height: calc(unit * 4 - 1px);
}
