@value unit, line-color, secondary-color, font-size-smaller, line-height-lowest, resetButton from '../../../../../../../ring-globals.css';

.tableHeader {
  display: contents;

  line-height: line-height-lowest;

  &::before {
    position: absolute;

    z-index: 1;
    top: var(--absolute-child-top);
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    pointer-events: none;

    border-bottom: 1px solid line-color;

    grid-column: start / end;
    grid-row: var(--row) / span 1;
  }
}

.columnTitle {
  composes: resetButton;

  cursor: pointer;
}

.statusColumn {
  grid-column-start: status-start;

  display: flex;

  white-space: nowrap;
}

.nameColumn {
  grid-column-start: name-start;
}

.durationColumn {
  grid-column-start: duration-start;

  text-align: right;
}

.runOrderColumn {
  grid-column-start: run-order-start;

  text-align: right;
}

.actionsColumn {
  grid-column-start: actions-start;
}

.statusColumn,
.nameColumn,
.durationColumn,
.runOrderColumn,
.actionsColumn {
  box-sizing: border-box;
  padding: unit 0;

  color: secondary-color;

  font-size: font-size-smaller;
  grid-row-start: var(--row);
}
