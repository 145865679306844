@value unit, text-color, secondary-color, icon-secondary-color, font-smaller-lower from '../../../ring-globals.css';

.button {
  height: auto;
  padding: 0;

  line-height: inherit;
}

.icon {
  margin-right: calc(unit / 2);

  color: icon-secondary-color;
}

.wrapper {
  composes: font-smaller-lower;

  display: flex;
  align-items: baseline;

  max-width: 100%;
}

.content {
  flex-grow: 1;

  min-width: 0;

  color: text-color;
}

.inline-comment-text {
  flex-grow: 1;

  min-width: 0;

  color: secondary-color;
}

.info {
  white-space: nowrap;

  color: text-color;
}

.user {
  padding: 0 calc(unit / 2);

  white-space: nowrap;

  color: secondary-color;
}

.text {
  min-width: 0;
}
