.name {
  margin-top: calc(var(--ring-unit) * 4);

  color: var(--ring-text-color);

  font-weight: bold;
}

.description {
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.description > a {
  color: var(--ring-secondary-color);
}

.actions {
  text-align: end;
}

.button {
  margin-left: var(--ring-unit);
}

.status {
  margin-top: var(--ring-unit);
}

.estimate {
  color: var(--ring-secondary-color);
}

.stop {
  margin-right: calc(var(--ring-unit) * -1);
}
