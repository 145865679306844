@value unit, error-color, icon-error-color, content-background-color from '../../../../../ring-globals.css';
@value leftShadow, rightShadow from '../TimelineStage.css';

.wrapper {
  position: absolute;
  z-index: 1;
  top: -2px;

  display: block;

  margin-left: calc(unit / -4 - 14px / 2);
}

.linkInner.linkInner.linkInner {
  border: none;
}

.pointIcon {
  color: icon-error-color;
  background-color: content-background-color;

  box-shadow: leftShadow, rightShadow;
}

.pointButton {
  height: calc(unit * 3 + 3px);
  padding: 0 calc(unit / 4) 3px;
}

.popup {
  pointer-events: none;
}

.popupContent {
  padding: 0 unit;

  white-space: nowrap;
}

.failure {
  color: error-color;
}
