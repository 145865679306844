.parameter {
  position: relative;

  display: flex;
  align-items: baseline;

  line-height: calc(var(--ring-unit) * 3);
}

.withIcon {
  margin-top: var(--ring-unit);

  &:first-child {
    margin-top: 0;
  }
}

.icon {
  margin-right: var(--ring-unit);

  &.icon svg {
    vertical-align: -5px;
  }
}

.edit,
.remove {
  margin-right: calc(var(--ring-unit) * -2);
  margin-left: auto;
}

.add {
  margin-top: calc(var(--ring-unit) + 2px);
}

.inputContainer {
  margin: 0 -2px;
}

.parameter:not(:focus-within) .input {
  border-color: transparent;
}

.parameter:focus-within .input {
  background-color: var(--ring-content-background-color);
}

.parameter:focus-within .edit,
.parameter:not(:focus-within) .remove {
  display: none;
}
