@value unit, selected-background-color, main-color, text-color from '../../../../ring-globals.css';

.item {
  line-height: 24px;
}

.item,
.item-wrapper,
.hoverable-link {
  outline: none;
}

.item-wrapper:focus,
.item:focus,
.focused-item {
  background-color: selected-background-color;
  box-shadow: inset 2px 0 main-color;
}

.hovered-item,
.hoverable-item:hover,
.active-item,
.highlighted-item {
  background-color: selected-background-color;
}

.hoverable-link:hover {
  text-decoration: none;

  color: text-color;
}

.active-item {
  font-weight: bold;
}

.counter {
  margin: 0 8px;
}
