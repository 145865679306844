@value unit, message-background-color, line-color, secondary-color from '../../../../ring-globals.css';

.dialog {
  width: calc(70vw - unit * 6 * 2); /* TODO: change to unit * 4 * 2 after fixing close button position */
  height: calc(70vh - unit * 2 * 2);
}

.overlay {
  padding: 0;

  background-color: message-background-color;
}

.wrapper {
  display: flex;

  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  height: 100%;
  padding: calc(unit * 2) calc(unit * 4) calc(unit * 4);
}

.header {
  padding-bottom: calc(unit * 4);
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;

  margin: unit calc(unit * -4) 0;
  padding: calc(unit * 2) calc(unit * 4) 0;

  box-shadow: 0 -1px line-color;
}

.noFiles {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: secondary-color;
}
