@value unit, secondary-color, font-size-smaller, main-color from '../../../../../ring-globals.css';

.wrapper {
  display: flex;
  align-items: center;

  min-height: calc(unit * 3);
}

.input {
  display: none;
}

.caption {
  display: inline-block;

  margin-left: unit;

  color: secondary-color;

  font-size: font-size-smaller;
}

.wrapper.dragover {
  border-radius: var(--ring-border-radius-small);
  outline: dashed 1px main-color;
  outline-offset: calc(unit / 2);
}
