@value unit from '../../../ring-globals.css';

.tree {
  position: relative;

  z-index: 0;

  margin-top: calc(unit * 2);
}

.expandAllButton,
.investigationMuteButtons {
  margin-right: unit;
}

.investigationMuteButtons {
  margin-left: unit;
}
