@value unit, ellipsis, line-height, font-size from '../../../ring-globals.css';

.wrapper {
  font-size: font-size;
  line-height: line-height;
}

.agent,
.agentPool {
  composes: ellipsis;

  font-size: inherit;
  line-height: inherit;
}
