@value font-family-monospace, line-height, ellipsis from '../../../ring-globals.css';

.copyButton {
  height: unset;
  padding-right: 0;

  opacity: 0;

  line-height: line-height;

  &:focus {
    opacity: 1;
  }
}

.wrapper {
  composes: ellipsis;

  display: inline-flex;

  font-family: font-family-monospace;

  &:hover .copyButton {
    opacity: 1;
  }
}
