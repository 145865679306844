@value lineClamp, lineClampHasOverflow, secondary-color from '../../../ring-globals.css';

.showButton {
  padding: 0;

  color: secondary-color;
}

.content {
  composes: lineClamp;
}

.hasOverflow {
  composes: lineClampHasOverflow;
}
