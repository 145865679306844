@value unit, font, secondary-color, link-hover-color from '../../../ring-globals.css';

.wrapper {
  composes: font;

  display: inline-flex;
}

.newUserInterfaceWrapper {
  display: inline-flex;
  align-items: baseline;

  min-width: 0;
  max-width: 100%;

  white-space: nowrap;

  line-height: normal;
}

.content {
  width: calc(unit * 90);
  padding: calc(unit * 2);
}

.dropdown {
  display: flex;
  justify-content: center;

  max-width: 100%;
}

.changes {
  margin-bottom: 0;
}

.secondary {
  color: secondary-color;
}

.link {
  overflow: hidden;
}

.label {
  overflow: hidden;
  flex-grow: 1;

  min-width: 0;
  margin: -2px;

  & .linkWrapper {
    display: flex;
    align-items: baseline;

    margin: 2px;
  }
}

.othersAvatar {
  transition: opacity var(--ring-fast-ease);

  opacity: 0.5;
}

.linkWrapper:hover .othersAvatar {
  transition: none;

  opacity: 1;
}

.anchorIcon {
  height: unset;
  padding-right: calc(unit / 4);
  padding-left: calc(unit / 4);

  line-height: inherit;

  &:hover {
    color: link-hover-color;
  }
}
