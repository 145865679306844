.document {
  overflow-y: auto;
}

.container {
  position: relative;
}

.main {
  overflow: auto;

  box-sizing: border-box;
  height: 100%;
  margin-left: calc(var(--ring-unit) * -1);
}
