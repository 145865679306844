@value unit, font-larger from '../../../ring-globals.css';

.errorPage {
  padding-top: calc(50vh - unit * 30);

  text-align: center;
}

.image {
  margin: calc(unit * -4) calc(unit * -6) 0 calc(unit * -13.5);

  vertical-align: calc(unit * -22.5);
}

.text {
  display: inline-block;

  max-width: calc(unit * 56.5);

  text-align: left;
}

.code.code,
.title.title {
  margin: 0;

  font-size: 32px;
  line-height: 34px;
  overflow-wrap: break-word;
}

.title.title {
  margin-bottom: 13px;
}

.description {
  composes: font-larger;

  margin-top: 0;
}

.details {
  height: auto;
}

.details.details svg {
  vertical-align: middle;
}

.detailsPopup {
  padding: unit calc(unit * 2) calc(unit * 2);
}

.retry {
  margin-right: unit;
}
