@value link-hover-color, content-background-color from '../../../../../ring-globals.css';

.hightlighting {
  pointer-events: initial;

  color: content-background-color;
  background-color: #ff8bcb;
}

.bright {
  background-color: link-hover-color;
}
