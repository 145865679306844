@value unit, secondary-color from '../../../../../ring-globals.css';

.toolbarContainer {
  display: flex;
  align-items: baseline;
}

.filterContainer {
  overflow: hidden;

  margin: calc(unit * -3) auto 0 -1px;
  padding-left: 1px;
}

.pendingChanges,
.runButton {
  margin-left: calc(unit * 2);
}

.noBuilds {
  margin-right: auto;

  color: secondary-color;
}
