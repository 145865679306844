@value unit, link-color, secondary-color, line-height, font-size-smaller, main-color from '../../../ring-globals.css';

.wrapper {
  font-size: font-size-smaller;
}

.wrapper .anchor {
  display: flex;
  align-content: baseline;

  height: unset;
  padding: 0;

  line-height: inherit;
}

.currentlyMutedAnchor .iconWrapper {
  color: main-color;
}

.popup {
  display: flex;
  overflow: hidden;

  width: calc(unit * 50);

  & > div {
    width: 100%;
  }
}

.state {
  word-break: break-word;
}

.actionButton {
  margin-right: unit;

  &:last-child {
    margin-right: 0;
  }
}

.assignAction {
  height: unset;
  padding: 0;

  color: secondary-color;

  line-height: line-height;
}

.comment,
.scope {
  margin-top: unit;
}

.comment {
  overflow: hidden;
}

.commentText {
  word-break: break-word;
}

.commentTextLoader {
  display: inline-block;
}

.scope {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}

.scopeTitle {
  margin-right: calc(unit / 2);
}

.scopeList {
  overflow: hidden;
  flex: 1;
}

.entityPath {
  flex: 1;

  line-height: line-height;
}

.entityPath .entityPathLink {
  color: link-color;
}

.title {
  margin-right: calc(unit / 2);
}

.muteDetails {
  margin: 0 calc(unit * -2) unit;
  padding: 0 calc(unit * 2) unit;
}

.muteDetails:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.withSeveralMutes .muteDetails {
  margin-bottom: calc(unit * 2);
  padding-bottom: calc(unit * 2);
}

.withSeveralMutes .muteDetails:last-child {
  margin-bottom: 0;
}

.body {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.content {
  overflow-y: auto;

  height: 100%;
  max-height: 400px;
  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.hasPermission .content {
  padding-bottom: 0;
}

.withSeveralMutes .content {
  padding-top: calc(unit * 2);
}

.footer {
  padding: calc(unit * 2);
}
