@value secondary-color from '../../../../../../../../ring-globals.css';
@value middle-ellipsis from '../../../../../../../common/MiddleEllipsis/MiddleEllipsis.css';

.entityPath,
.entityPathLink,
.buildStatus {
  line-height: inherit;
}

.buildStatus {
  margin-bottom: -1px;
}

.fixedTest {
  color: secondary-color;

  &,
  & .middle-ellipsis {
    text-decoration: line-through;
  }
}

.newTest {
  font-weight: bold;
}
