@value unit, secondary-color, hover-background-color, popup-border-color from '../../../../ring-globals.css';

.loader {
  display: block;

  width: 16px;

  margin: 0 calc(4 * unit);
}

.search-input,
.tree,
.panel {
  line-height: calc(3 * unit);
}

.search-input {
  margin: 0 calc(4 * unit) unit;
}

.tree {
  overflow: auto;
  flex: 1 1;
}

.empty-list {
  margin: 0 calc(4 * unit);
}

.expandable-group {
  margin: 0 0 calc(4 * unit) 0;
}

.expandable-group-header {
  overflow: hidden;

  padding: 0 calc(4 * unit);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.pool-item {
  overflow: hidden;

  padding-right: calc(4 * unit);

  padding-left: calc(8 * unit);

  white-space: nowrap;
  text-overflow: ellipsis;
}

.checkbox-container {
  display: inline;
}

.item-checkbox-container {
  display: flex;
  align-items: center;

  height: calc(3 * unit);

  line-height: calc(3 * unit);
}

.checkbox-cell {
  top: 0;

  flex-shrink: 0;
}

.checkbox-label {
  position: relative;
  z-index: 10;

  overflow: hidden;

  flex-grow: 1;

  cursor: pointer;

  user-select: none;

  text-overflow: ellipsis;
}

.pool-item:hover {
  background: hover-background-color;
}

.summary {
  margin-bottom: calc(unit);
}

.item-title {
  display: flex;
}

.item-name {
  overflow: hidden;

  flex: 1 1;

  text-overflow: ellipsis;
}

.item-label {
  flex: 0 0;

  color: secondary-color;
}

.panel.panel {
  margin-top: 0;
  padding-left: calc(4 * unit);

  border-top: 1px solid popup-border-color;
}
