@value unit, secondary-color, font-size-smaller from '../../../../../ring-globals.css';

.wrapper {
  display: flex;
  align-items: baseline;
}

.popup {
  max-width: 500px;
  padding: calc(unit * 2);
  padding-bottom: unit;
}

.visibleBranchLabel {
  margin-right: unit;
}

.branchLabel {
  margin-right: unit;
  margin-bottom: unit;
}

.anchor {
  white-space: nowrap;

  color: secondary-color;

  font-size: font-size-smaller;
}
