.status-success {
  color: var(--ring-success-color);
}

.status-failure {
  color: var(--ring-error-color);
}

.status-queued,
.status-canceled {
  color: var(--ring-secondary-color);
}

.icon {
  margin-right: calc(var(--ring-unit) / 2);
}
