@value unit, secondary-color, error-color from '../../../../ring-globals.css';

.result {
  margin-top: calc(unit * 2);
}

.code {
  outline: none;
}

.empty {
  color: secondary-color;
}

.error {
  color: error-color;
}
