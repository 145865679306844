@value unit, secondary-color, contentWidth from '../../../../../ring-globals.css';

.header {
  composes: row from '../../../../CollapsibleLine/CollapsibleLine.css';

  position: relative;

  justify-content: flex-start;

  margin-top: 0;

  margin-bottom: calc(unit);
}

.root {
  margin-top: calc(unit * 3);
}

.limitWidth {
  max-width: contentWidth;
}

.archived,
.showArchivedProjectsButton {
  margin-top: calc(unit * 2);
}

.hideArchivedProjectsButton {
  padding-left: 0;

  color: secondary-color;
}
