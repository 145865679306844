@value dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown.css';
@value unit, link-color, secondary-color, text-color from '../../../../../ring-globals.css';

.wrapper .dropdown {
  display: block;
}

.popup {
  overflow: hidden;

  padding: calc(unit * 1.5) calc(unit * 2) calc(unit * 2);
}

.popup .heading {
  margin-bottom: unit;
}

.headingLink {
  color: text-color;
}

.statusRow {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  margin-bottom: calc(unit * 1.5);

  &:last-child {
    margin-bottom: 0;
  }
}

.statusRowHeading {
  margin-bottom: calc(unit / 2);
}

.buildPath {
  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit / 2);

  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }
}

.entityPathLink {
  color: link-color;
}

.separator {
  margin-right: 1px;

  color: secondary-color;
}

.showAllButton {
  padding: 0;

  color: secondary-color;
}
