@value unit, alert-z-index from '../../../../ring-globals.css';
@value alertZIndex: alert-z-index;

.dialog {
  z-index: calc(alertZIndex + 1);
}

.dialogContent {
  width: calc(unit * 80);
}

.code {
  margin-top: unit;
}

.content {
  padding-bottom: calc(unit * 4);
}
