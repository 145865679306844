@value unit, disabled-color from '../../../ring-globals.css';
@value glyph from '@jetbrains/ring-ui/components/icon/icon.css';

.icon {
  margin-top: -2px;
  margin-left: unit;

  color: disabled-color;
}

.icon .glyph {
  vertical-align: -5px;
}
