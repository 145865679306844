@value unit, error-color, line-height-lowest, font-size-smaller from '../../../../ring-globals.css';

.wrapper {
  max-width: calc(unit * 16);
}

.port {
  margin-top: calc(unit / 2);
}

.port input::-webkit-outer-spin-button,
.port input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.port input {
  -moz-appearance: textfield;
}

.error {
  min-height: line-height-lowest;
  margin-top: calc(unit / 4);

  color: error-color;

  font-size: font-size-smaller;
  line-height: line-height-lowest;
}
