@value unit, secondary-color, hover-background-color, line-color, icon-color from '../../../../ring-globals.css';

.toolbar {
  display: flex;
  align-items: center;

  height: calc(4.25 * unit);

  margin-bottom: calc(unit * 2);
}

.assign-button {
  margin-right: calc(unit);
}

.excluded-projects-number {
  margin: calc(2 * unit) 0;
}

.filterButton {
  margin-right: calc(2 * unit);
}

.hide-archived-checkbox {
  margin: 0;
}

.table-row {
  display: flex;
  flex-direction: row;

  height: calc(3 * unit);

  margin-right: calc(-4 * unit);
  margin-left: calc(-4 * unit);

  line-height: calc(3 * unit);
}

.table-header {
  margin-bottom: unit;
  padding-bottom: unit;

  color: secondary-color;

  border-bottom: 1px solid line-color;
}

.nameHeader {
  margin-left: calc(unit * 4 - 5px);
}

.table-row:hover,
.table-row:focus-within {
  outline: none;
  background-color: hover-background-color;
}

.table-row:hover .pathItem,
.table-row:focus-within .pathItem {
  background-color: hover-background-color;
}

.table-row:focus-within {
  box-shadow: 2px 0 var(--ring-main-color) inset;
}

.table-header:hover {
  background-color: transparent;
}

.expandable-table-row {
  cursor: pointer;
}

.table-cell {
  flex: 1 0;
}

.name-table-cell {
  margin-left: 5px;
}

.projects-table-cell {
  margin-right: calc(3 * unit);
  padding-left: 2px;
}

.unassign-icon {
  color: icon-color;
}
