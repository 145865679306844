@value unit, icon-secondary-color from '../../../../ring-globals.css';

.container {
  white-space: nowrap;
}

.expand-handle-container {
  margin-left: unit;
}

.icon {
  padding: 0;
}
