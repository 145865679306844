@value unit from '../../../ring-globals.css';

.name {
  width: 100%;
  margin-bottom: unit;
}

.max-agents {
  width: calc(15 * unit);
  margin-bottom: calc(-1.5 * unit);
}

.max-agents-input::-webkit-outer-spin-button,
.max-agents-input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.max-agents-input[type='number'] {
  -moz-appearance: textfield;
}
