@value unit, font-size-smaller, secondary-color, ellipsis from '../../../../../../../ring-globals.css';

.name {
  composes: ellipsis;
}

.suite,
.package,
.class {
  user-select: text;

  & > * {
    border-bottom-width: 0 !important;
  }
}

.path {
  display: inline;

  & .suite,
  & .package {
    margin-left: calc(unit / 2);

    font-size: font-size-smaller;
  }

  &:not(:hover) .suite,
  &:not(:hover) .package {
    color: secondary-color;
  }
}
