@value unit, secondary-color, hover-background-color, font-size-smaller, sidebar-background-color, main-color from '../../../../../ring-globals.css';

.changed {
  background-color: sidebar-background-color;
  box-shadow: inset 3px 0 main-color;
}

.row {
  margin: 0 calc(unit * -4);

  &:hover {
    background-color: hover-background-color;

    & .changed {
      background-color: hover-background-color;
    }
  }
}

.col {
  padding: 0;
}

.name {
  padding: 0 calc(unit * 4);

  overflow-wrap: break-word;

  color: secondary-color;

  font-size: font-size-smaller;
}

.values {
  margin: 0;
  padding: 1px calc(unit * 3) 3px;
}

.value {
  margin-top: 0;
  margin-bottom: 0;

  overflow-wrap: break-word;
}

.empty {
  color: secondary-color;
}
