@value unit, main-color, secondary-color, line-color, font-size-smaller from '../../../../ring-globals.css';

.toggleContainer {
  display: block;

  margin-bottom: unit;
}

.noBuildTypes {
  box-sizing: border-box;
  margin-top: unit;

  padding: unit calc(unit * 2);

  text-align: left;

  color: secondary-color;

  border: 1px solid line-color;
  border-radius: 3px;
  background: white;

  font-size: font-size-smaller;
}

.createLink {
  padding-left: unit;

  color: main-color;
}

.expandAllButton {
  margin-right: auto;
  margin-left: calc(unit * 2);
}
