@value unit, line-color, sidebar-background-color, link-hover-color, secondary-color, border-radius, border-radius-small from '../../../ring-globals.css';

.queuedWrapper {
  padding: calc(unit / 2) 0;

  text-align: center;

  border-bottom: 1px solid line-color;

  background-color: sidebar-background-color;
}

.queuedButton {
  width: 100%;

  color: secondary-color;

  &:hover {
    color: link-hover-color;
  }
}

.noBuilds {
  composes: noBuildTypes from '../../pages/ProjectPage/ProjectOverviewTab/ProjectOverviewTab.css';

  grid-column: start / end;

  margin: 1px calc(unit * -2) 2px;
}

.content {
  margin-bottom: calc(unit * 1.5);

  border: 1px solid line-color;
  border-radius: border-radius;
  background: white;
}

.buildListWrapper.buildListWrapper {
  margin-bottom: -1px;
  padding: 0 calc(unit * 2);
}

.hasParentGrid {
  display: contents;
}

.content.hasParentGrid::before {
  position: absolute;
  top: calc(var(--absolute-child-top) - 1px);
  right: calc(unit * -2 - 1px);
  bottom: 0;
  left: calc(unit * -2 - 1px);

  content: '';
  pointer-events: none;

  border: 1px solid line-color;
  border-radius: border-radius;
  grid-column: start / end;
  grid-row: var(--row) / span var(--row-span);
}

.inHierarchy.hasParentGrid::before {
  left: calc(unit * -2 - 1px + var(--offset));
}

.withQueued {
  composes: withQueued from './BuildDetails/BuildDetails.css';
}

.placeholder {
  width: 100%;
  height: 32px;

  border: 1px solid black;
}

.stopPlaceholderAnimation {
  composes: stopAnimation from './Build/Build.css';
}

.queuedToggler {
  border-top-left-radius: border-radius-small;
  border-top-right-radius: border-radius-small;
}

.hasParentGrid .queuedToggler {
  grid-column: start / end;

  margin: 0 calc(unit * -2);
}

.showMoreQueuedBulds {
  grid-column: start / end;

  margin: 0 calc(unit * -2) 0;
}
