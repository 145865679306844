@value unit, icon-secondary-color, font-smaller-lower from '../../../ring-globals.css';

.wrapper {
  composes: font-smaller-lower;

  display: flex;
  align-items: baseline;

  max-width: 900px;
}

.icon {
  flex: 0 0 auto;

  margin-right: calc(unit / 2);

  color: icon-secondary-color;
}
