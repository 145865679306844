@value unit, link-color, secondary-color, line-height-lowest, font-size-smaller from '../../../../ring-globals.css';

.node {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  flex-wrap: nowrap;

  line-height: line-height-lowest;
}

.detailedNode {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
}

.entityPath,
.buildNumber {
  line-height: inherit;
}

.entityPathLink {
  color: link-color;

  line-height: inherit;
}

.testPath {
  overflow: hidden;

  margin: calc(unit / 2) 0;
}

.childrenCount {
  margin-left: unit;

  user-select: none;

  white-space: nowrap;

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lowest;
}

.detailedNode .childrenCount {
  margin-left: unit;
}

.separator {
  flex-shrink: 0;

  color: secondary-color;
}
