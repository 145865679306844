@value line-height, unit, success-color, secondary-color, error-color from '../../../../ring-globals.css';

.instances-dropdown {
  line-height: line-height;
}

.popup {
  width: calc(50 * unit);
  margin-left: calc(-2 * unit);
  padding: calc(unit) calc(2 * unit);

  line-height: calc(4 * unit);
}

.item {
  display: flex;
  justify-content: space-between;
}

.link {
  overflow: hidden;

  margin-right: calc(unit);

  text-overflow: ellipsis;
}

.link-inner {
  white-space: nowrap;
}

.running-status {
  white-space: nowrap;

  color: success-color;
}

.icon {
  margin-right: calc(0.5 * unit);
}

.idle-status,
.disabled-status {
  color: secondary-color;
}

.disconnected-status {
  color: error-color;
}

.view-all-link {
  color: secondary-color;
}
