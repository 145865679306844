@value unit, main-color, font-smaller-lower, icon-secondary-color from '../../../ring-globals.css';

.wrapper {
  composes: font-smaller-lower;
}

.icon {
  margin-right: calc(unit / 2);

  color: icon-secondary-color;

  &.myBuild {
    color: main-color;
  }
}
