@value unit, link-color, link-hover-color, icon-color from '../../../../../ring-globals.css';

.cell {
  display: flex;
  overflow: hidden;
  justify-content: space-between;

  white-space: nowrap;

  text-overflow: ellipsis;
}

.pools {
  overflow: hidden;

  min-width: 0;
  margin: 0 -2px;
  padding: 0 2px;

  text-overflow: ellipsis;
}

.actions {
  flex: 0 0;

  padding-left: calc(2 * unit);
}

.unassign-icon {
  color: icon-color;
}

.undo-button {
  padding: 0 unit 0 calc(2 * unit);
}
