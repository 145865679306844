@value unit, line-color, main-color, content-background-color, sidebar-background-color, border-radius from '../../../../../ring-globals.css';

.previewContainer {
  overflow: hidden;

  height: 32px;

  margin-bottom: unit;

  cursor: pointer;

  border-radius: border-radius;
  background-color: sidebar-background-color;
  box-shadow: 1px 1px 0 0 line-color inset, -1px -1px 0 0 line-color inset;
}

.preview {
  position: relative;
}

.brush {
  width: 100%;
  height: 100%;

  pointer-events: none;

  border-radius: border-radius;
  background: content-background-color;
  box-shadow: 0 0 0 1px main-color inset;
}

.victoryContainer > svg {
  overflow: visible;
}
