@value unit, font-size, line-height from '../../../../../ring-globals.css';

.wrapper {
  line-height: line-height;
}

.content {
  max-width: 432px;
  padding: calc(unit * 2);
}

.visibleContainer {
  overflow: hidden;
}

.info {
  composes: info from '../../../../common/BuildInfoTable/BuildInfoTable.css';
}

.row {
  margin-bottom: calc(unit * 1.5);

  font-size: font-size;

  &:last-child {
    margin-bottom: 0;
  }
}

.buildQueueInfo {
  font-size: font-size;
}
