.repositories {
  width: calc(var(--ring-unit) * 58);
  height: 100%;

  border-right: 1px solid var(--ring-line-color);
}

.scripts {
  display: none;
}

.error {
  color: var(--ring-error-color);
}
