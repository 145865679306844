@value unit, text-color from '../../../../../ring-globals.css';

.wrapper {
  margin-bottom: unit;

  line-height: 24px;
}

.select {
  margin-bottom: unit;

  & > button {
    color: text-color;
  }
}

.selectPopup {
  min-width: 640px;
}
