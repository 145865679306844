@value contentWidth, unit from '../../../ring-globals.css';

.wrapper {
  max-width: contentWidth;
}

.header {
  composes: header from '../ProjectPage/ProjectPageHeader/ProjectPageHeader.css';
}

.headerWrapper {
  composes: headerWrapper from '../ProjectPage/ProjectPageHeader/ProjectPageHeader.css';

  margin-top: calc(unit * 3.5);
  margin-bottom: unit;
}
