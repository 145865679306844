@value unit, content-background-color from '../../../ring-globals.css';

.authorize-agent {
  display: inline-block;
}

.comment {
  width: 100%;
  margin-bottom: calc(1.5 * unit);
}

.poolSelectPopup {
  z-index: 101;
}

.authorize-agent[disabled] {
  cursor: default;
  pointer-events: auto;
}

.authorize-agent[disabled]:active {
  background-color: content-background-color;
}
