.wrapper {
  position: relative;

  margin-top: calc(var(--ring-unit) * 2);
}

.edges {
  position: relative;
  fill: transparent;
  stroke: var(--ring-icon-color);

  pointer-events: none;
}
