@value unit, hover-background-color, font-size, line-height-taller from '../../../../ring-globals.css';
@value lineHeightTaller: line-height-taller;

.compareBuildsHeader {
  margin-top: unit;

  font-size: font-size;
  line-height: line-height-taller;
}

.grid {
  margin-top: 0;
  margin-bottom: calc(unit * 2);

  padding-right: 0;
  padding-left: 0;
}

.row:hover {
  background-color: hover-background-color;
}

.column {
  margin-top: calc(unit / 2);
  margin-bottom: calc(unit / 2);
}

.invisible {
  visibility: hidden;

  opacity: 0;
}

.buildPath {
  position: relative;
  top: 1px;
}

.buildNumber {
  font-size: inherit;
  line-height: inherit;
}

.editIcon {
  height: lineHeightTaller;
  margin-top: -1px;
}
