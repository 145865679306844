.heading {
  display: flex;
  align-items: baseline;
}

.delete {
  margin-right: calc(var(--ring-unit) * -1);
  margin-left: auto;
}

.deleted {
  margin-left: auto;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.restore {
  margin-right: calc(var(--ring-unit) * -1.5);
  padding: 0 calc(var(--ring-unit) * 1.5);
}
