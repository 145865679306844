@value unit, main-color, success-color, search-color, warning-color from '../../ring-globals.css';

.inactiveCircle {
  stroke-width: 1;
  stroke: var(--ring-secondary-color);
  fill: none;
}

.activeCircle {
  z-index: 1;
  stroke-width: 3;
  stroke: main-color;
  fill: none;

  animation: circleLoader 1s ease-in;
}

.stopAnimation {
  animation: none;
}

@keyframes circleLoader {
  0% {
    stroke-dasharray: 0 100;
  }
}

.greenActive {
  stroke: success-color;
}

.yellowActive {
  stroke-width: 3;
  stroke: warning-color;
}

.blueInactive {
  stroke: search-color;
}
