@value unit, font-size-smaller, line-height-lower, secondary-color  from '../../../../ring-globals.css';

.timestamp.timestamp {
  padding: unit calc(unit * 2) 0;

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lower;
}

.separator {
  min-height: 0;
  padding-bottom: 0;
}

.popup {
  min-width: calc(unit * 29);
}
