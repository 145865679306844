.labelCreate {
  visibility: hidden;

  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
}

.labelPrivate {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.list {
  margin-left: calc(var(--ring-unit) * -4);
}

.item {
  position: relative;
}

.itemHover .labelCreate {
  visibility: visible;
}

.image {
  position: absolute;
  top: calc(var(--ring-unit) / 2);
  left: calc(var(--ring-unit) * 4);

  width: calc(var(--ring-unit) * 3);
  height: calc(var(--ring-unit) * 3);
  margin-right: var(--ring-unit);

  border-radius: var(--ring-border-radius);
}
