@value font, unit, text-color, main-color, secondary-color, icon-secondary-color, selected-background-color, icon-hover-color, ease, line-height-lower from '../../../../ring-globals.css';
@value verticalCompensation: 2px;

.item {
  position: relative;

  list-style-type: none;

  cursor: pointer;

  line-height: calc(unit * 3 - verticalCompensation);
}

.link {
  flex-grow: 1;

  border-radius: 0;
}

.link,
.innerLink {
  color: text-color;
}

.heading,
.link {
  display: flex;
  align-items: baseline;

  padding-right: unit;
  padding-bottom: verticalCompensation;

  &:hover {
    background: selected-background-color;
  }
}

.item:focus > .heading,
.link:focus,
.item[data-focus-visible-added] > .heading,
.link[data-focus-visible-added] {
  background: selected-background-color;
  box-shadow: inset 2px 0 main-color;
}

.item:focus,
.link:focus,
.item[data-focus-visible-added],
.link[data-focus-visible-added] {
  outline: none;
}

.chevronIcon {
  position: absolute;
  top: 0;

  transition: color ease;

  color: icon-secondary-color;

  @nest .item[data-focus-visible-added] > .heading > &,
  .item:focus > .heading > &,
  .heading:hover > & {
    transition: none;

    color: icon-hover-color;
  }

  @nest .item:active > .heading:hover > & {
    transition: none;

    color: main-color;
  }

  @nest .interactive.interactive:hover ~ &,
  .interactive.interactive:active ~ & {
    transition: color ease;

    color: icon-secondary-color;
  }
}

.name {
  margin-right: unit;
  margin-left: calc(unit / 2);
}

.link .name {
  line-height: line-height-lower;
}

.innerLink {
  composes: name;
  composes: interactive;
}

.icon {
  color: rgba(154, 167, 176, 0.8);

  line-height: normal;
}

.size {
  margin-right: unit;

  white-space: nowrap;

  color: secondary-color;
}

.extensionIcon {
  composes: interactive;
}
