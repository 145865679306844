@value font, secondary-color, error-color, success-color, warning-color from '../../../../ring-globals.css';

.link {
  composes: font;

  min-width: 0;
}

.success {
  color: success-color;
}

.failure {
  color: error-color;
}

.canceled {
  color: secondary-color;
}

.warning {
  color: warning-color;
}
