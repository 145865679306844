@value unit, secondary-color from '../../../ring-globals.css';

.tree {
  margin: 0;
  padding: 0;
}

.root {
  margin-bottom: unit;
}

.autoWidth {
  display: inline-block;

  min-width: calc(unit * 40);
}

.container {
  position: relative;
}

.toggleHidden {
  display: block;

  margin-left: calc(unit * 4);
}

.secondary {
  color: secondary-color;
}

.noteHidden {
  margin: 0 calc(unit * 4);

  color: secondary-color;
}

.noArtifacts {
  margin: 0 calc(unit * 4) unit;
}

.downloadLink {
  position: absolute;
  top: 0;
  right: 0;
}

.artifactsSize {
  padding: 0 calc(unit * 4);
}

.noArtifactsHint {
  max-width: 260px;

  margin-top: unit;
}
