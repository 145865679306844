.label {
  display: flex;

  margin-bottom: var(--ring-unit);

  line-height: calc(var(--ring-unit) * 3);
}

.select {
  margin-left: auto;
}

.add {
  margin-top: 2px;
}
