@value unit, warning-color, icon-warning-color from '../../../ring-globals.css';

.button {
  height: auto;
  padding: 0;

  line-height: inherit;
}

.icon {
  color: icon-warning-color;
}

.inlineIcon {
  margin-right: calc(unit / 2);

  color: icon-warning-color;
}

.popup {
  max-width: 400px;
  padding: unit calc(2 * unit);

  text-align: left;
}

.warning {
  margin-right: unit;

  color: warning-color;
}
