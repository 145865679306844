@value button, heightS, button-shadow from '@jetbrains/ring-ui/components/button/button.css';
@value unit from '../../../ring-globals.css';

.container {
  display: inline-block;

  line-height: calc(unit * 5);
}

.fileInputWrapper {
  position: relative;

  display: inline-block;

  margin-right: unit;
}

.fileInput {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  opacity: 0;
}

.fileInputLabel {
  composes: button;
  composes: heightS;
}

.fileInput:hover + .fileInputLabel {
  transition: none;

  box-shadow: button-shadow var(--ring-border-hover-color);
}

.fileInput:global(.focus-visible) + .fileInputLabel {
  transition: none;

  box-shadow: button-shadow var(--ring-border-hover-color), 0 0 0 1px var(--ring-border-hover-color);
}

.fileInput:active + .fileInputLabel {
  transition: none;

  box-shadow: button-shadow var(--ring-main-color);
}

.fileInput[disabled] + .fileInputLabel {
  color: var(--ring-disabled-color);
  background-color: var(--ring-disabled-background-color);
  box-shadow: button-shadow var(--ring-border-disabled-color);
}

.avatarWrapper {
  margin-right: calc(unit * 2);
}

.avatar {
  vertical-align: -15px;
}

.dialog {
  width: auto;
}
