@value unit, error-color, line-height-taller, font-size-smaller from '../../../../ring-globals.css';

.tabs {
  width: fit-content;
  margin-bottom: calc(unit * 2);
}

.radioItem span {
  line-height: line-height-taller;
}

.terms {
  margin-bottom: calc(unit * 1.5);
}

.uploadForm {
  display: flex;
  flex-direction: column;
  row-gap: unit;
}

.formMargin {
  margin-top: calc(unit / 2);
}

.portConfig {
  margin: calc(unit / 2) 0;
}

.errorDescription {
  margin-top: unit;

  color: error-color;

  font-size: font-size-smaller;
}

.buttons {
  margin-top: calc(unit * 1.5);
}

.btnCancel {
  margin-right: calc(unit / 2);
  margin-left: unit;
}
