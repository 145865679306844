@value hover-background-color, main-color from '../../../../ring-globals.css';

.wrapper {
  outline: none;

  &:hover {
    color: inherit;
    background-color: hover-background-color;
  }

  &.wrapper:focus,
  &.wrapper[data-focus-visible-added] {
    background-color: hover-background-color;
    box-shadow: inset 2px 0 main-color;
  }
}
