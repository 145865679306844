@value unit, secondary-color, line-color, main-color, hover-background-color, icon-color, success-color, error-color from '../../ring-globals.css';

.form {
  display: flex;
}

.input {
  flex-grow: 1;
}

.add {
  margin-left: calc(unit * 2);
}

.list {
  margin: calc(unit * 2) 0;
  padding: 0;
}

.item {
  display: flex;
  align-items: baseline;

  height: calc(unit * 4);
  margin-top: 1px;

  list-style-type: none;

  white-space: nowrap;

  box-shadow:
    0 1px line-color,
    0 -1px line-color;

  &:hover {
    background-color: hover-background-color;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 1px line-color,
      0 -1px line-color,
      inset 2px 0 main-color;
  }

  & > * {
    margin: calc(unit / 2);
  }
}

.note {
  margin: calc(unit * 2) 0;

  color: secondary-color;
}

.saveContainer {
  display: flex;
}

.save {
  margin-left: auto;
}

.handle {
  color: icon-color;
}

.dragged .handle {
  color: main-color;
}

.url {
  flex-grow: 1;
}

.success {
  color: success-color;
}

.error {
  overflow: hidden;

  text-overflow: ellipsis;

  color: error-color;
}
