@value unit, error-color, success-color, font-size-smaller, line-height-lowest, link-hover-color from '../../../../ring-globals.css';
@value descriptionHeight: line-height-lowest;

.header {
  min-height: calc(28px + descriptionHeight);
  padding: calc(unit / 2) calc(unit * 2) unit;
}

.title.title {
  margin: 0;
  padding: 0;

  font-size: 24px;

  font-weight: bold;
  line-height: 28px;
}

.link {
  color: inherit;

  &:hover {
    color: link-hover-color;

    & > .linkInner {
      border-bottom-width: 2px;
    }
  }
}

.title.failure {
  color: error-color;
}

.title.success {
  color: success-color;
}

.statusIcon {
  margin-right: calc(unit / 2);
}

.description {
  font-size: font-size-smaller;
  line-height: line-height-lowest;
}

.descriptionText {
  line-height: line-height-lowest;
}
