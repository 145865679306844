@value unit from '../../../../../ring-globals.css';

.grid {
  --column-width: minmax(0, max-content);
  --name-column: [start name-start] auto;
  --duration-column: [duration-start] var(--column-width);
  --count-column: [count-start] var(--column-width);
  --success-column: [success-start] 0;
  --failure-column: [failure-start] 0;
  --muted-column: [muted-start] 0;
  --ignored-column: [ignored-start] 0;

  &.withSuccessColumn {
    --success-column: [success-start] var(--column-width);
  }

  &.withFailureColumn {
    --failure-column: [failure-start] var(--column-width);
  }

  &.withMutedColumn {
    --muted-column: [muted-start] var(--column-width);
  }

  &.withIgnoredColumn {
    --ignored-column: [ignored-start] var(--column-width);
  }

  position: relative;
  grid-template-columns: var(--name-column) var(--duration-column) var(--count-column) [end];

  &.withCountersColumns {
    grid-template-columns: var(--name-column) var(--duration-column) var(--count-column) var(--success-column) var(--failure-column) var(--muted-column) var(--ignored-column) [end];
  }
}

.grid.withOverlay::before {
  position: absolute;
  top: var(--absolute-child-top);
  right: 0;
  bottom: 0;
  left: 0;

  grid-column: start / end;
  grid-row: 2 / auto;

  content: '';

  cursor: wait;

  background-color: rgba(255, 255, 255, 0.7);
}
