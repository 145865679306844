@value unit, icon-secondary-color, secondary-color, font-size-smaller, line-height-lowest from '../../../ring-globals.css';

.label {
  display: inline-block;

  color: secondary-color;

  font-size: font-size-smaller;
  line-height: line-height-lowest;
}

.icon {
  margin-right: calc(unit / 2);

  color: icon-secondary-color;
}
