@value unit from '../../ring-globals.css';

.agent-toggle {
  position: relative;

  display: inline-block;
}

.comment {
  width: 100%;
}

.comment-icon {
  margin-right: calc(unit);
}

.timer-icon {
  margin-right: calc(unit);
}

.restore-times {
  margin-left: calc(0.5 * unit);
}

.timeoutSelect {
  z-index: 101;
}
