@value unit from '../../../ring-globals.css';

.iframe {
  flex-grow: 1;

  width: 1px;

  min-width: calc(100% + unit * 8);
  margin: 0 calc(unit * -4);

  border: none;
}

.loading {
  visibility: hidden;
}
