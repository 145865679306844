@value unit, line-color, borders-color, icon-success-color, error-color, icon-error-color, success-background-color, error-background-color, border-hover-color, fixed-z-index, fast-ease, icon-secondary-color, secondary-color from '../../../../ring-globals.css';
@value patternSize: unit;

.column {
  position: relative;

  width: calc(unit * 1.5);

  transition: background-color fast-ease;

  &::before {
    position: absolute;
    z-index: fixed-z-index;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;

    content: '';

    border-radius: 2px;
  }
}

.half {
  min-height: 1px;
}

.success {
  background-color: success-background-color;
  box-shadow: inset 0 1px icon-success-color;
}

.failed {
  background-color: icon-error-color;
}

.canceled {
  background-color: icon-secondary-color;
}

.timeInQueue {
  margin-top: 1px;

  background-color: line-color;
}

@keyframes bar-move {
  from {
    transform: translateY(calc(patternSize * -1));
  }

  to {
    transform: translateY(0);
  }
}

.running {
  overflow: hidden;

  &::before {
    display: block;

    height: calc(100% + patternSize);

    content: '';

    background-repeat: repeat;
    background-position: bottom;
    background-size: patternSize patternSize;
  }

  &.success {
    &::before {
      background-image:
        linear-gradient(
          135deg,
          icon-success-color,
          icon-success-color 3.75%,
          transparent 3.75%,
          transparent 46.25%,
          icon-success-color 46.25%,
          icon-success-color 53.75%,
          transparent 53.75%,
          transparent 96.25%,
          icon-success-color 96.25%,
          icon-success-color
        );
    }

    box-shadow: none;
  }

  &.failed::before {
    background-image:
      linear-gradient(
        45deg,
        error-background-color,
        error-background-color 3.75%,
        transparent 3.75%,
        transparent 46.25%,
        error-background-color 46.25%,
        error-background-color 53.75%,
        transparent 53.75%,
        transparent 96.25%,
        error-background-color 96.25%,
        error-background-color
      );
  }
}

.animate::before {
  animation: bar-move 1s linear infinite;
}

.buildLink {
  display: block;

  outline: none;

  /* stylelint-disable-next-line selector-max-specificity */
  &.current .column::before {
    box-shadow: 0 0 0 3px rgba(0, 142, 255, 0.4);
  }

  /* stylelint-disable-next-line selector-max-specificity */
  &:focus .column::before,
  &[data-focus-visible-added] .column::before {
    box-shadow: 0 0 0 3px border-hover-color;
  }

  &:not(:first-child) {
    margin-left: 1px;
  }
}

.hovered {
  & .column {
    transition: none;
  }

  & .success {
    background-color: #bcdcc2;
  }

  & .failed {
    background-color: error-color;
  }

  & .canceled {
    background-color: secondary-color;
  }

  & .timeInQueue {
    background-color: borders-color;
  }
}

.popup {
  padding: unit calc(unit * 3) calc(unit * 2);
}
