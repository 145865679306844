@value error-color, selected-background-color from '../../../../../../ring-globals.css';

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  height: 2px;
}

.body {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0.25;
  background: #aaa;
}

.failedLine {
  width: 100%;
  height: 100%;
}

.failed {
  & .failedLine {
    opacity: 0.2;
    background: error-color;
  }

  & .body {
    background: error-color;
  }
}

.column {
  position: relative;

  width: 100%;
  height: 100%;

  cursor: pointer;

  &:hover .body {
    opacity: 1;

    background: selected-background-color;
  }
}
