@value unit, contentWidth, icon-warning-color, error-color, icon-color, secondary-color, font-smaller-lower, line-height from '../../../../ring-globals.css';

.row,
.warning-row {
  line-height: calc(4 * unit);
}

.row {
  display: flex;
  flex-direction: row;
}

.row-title {
  width: calc(20 * unit);
}

.edit-link {
  margin-left: calc(unit);

  color: secondary-color;
}

.edit-icon {
  color: icon-color;
}

.warinig-icon {
  color: icon-warning-color;
}

.running-icon {
  margin-right: calc(unit / 2);
}

.running-build-heading.running-build-heading {
  margin-bottom: unit;
  padding: 0;

  font: inherit;
  font-weight: bold;
}

.running-build-placeholder {
  composes: font-smaller-lower;

  align-self: center;

  flex: 1 0;
}

.plugins {
  margin-top: calc(2 * unit);
}

.copy-host-button {
  height: line-height;

  color: secondary-color;

  line-height: line-height;
}
