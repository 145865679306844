.container {
  display: flex;

  margin-bottom: calc(var(--ring-unit) * 2);
}

.right {
  margin-left: auto;
}

.button {
  margin-left: var(--ring-unit);
}

.unsaved {
  color: var(--ring-warning-color);

  background-color: var(--ring-content-background-color);

  line-height: calc(var(--ring-unit) * 3);
}

.unsavedMarker {
  display: inline-block;

  margin-right: calc(var(--ring-unit) / 2);
}
