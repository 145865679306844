@value unit, secondary-color, icon-secondary-color, font-smaller from '../../../../ring-globals.css';

.buildDurationDetails {
  composes: font-smaller;

  display: table;

  padding: unit calc(3 * unit);

  text-align: left;

  line-height: calc(3 * unit);
}

.row {
  display: table-row;
}

.label {
  position: relative;

  display: table-cell;

  padding-right: unit;

  white-space: nowrap;

  color: secondary-color;
}

.value {
  display: table-cell;

  white-space: nowrap;
}

.overtimeIcon {
  position: absolute;

  top: 3px;
  left: -20px;

  color: icon-secondary-color;
}
