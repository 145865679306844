/* ensure correct styles order */
@import '@jetbrains/ring-ui/components/island/island.css';
@import '@jetbrains/ring-ui/components/dialog/dialog.css';
@import '../common/ProjectsTree/ProjectsTree.css';

@value unit, font-smaller-lower, secondary-color, line-color from '../../ring-globals.css';

.dialog {
  width: calc(unit * 66);
  height: calc(100vh - unit * 8);
  max-height: calc(unit * 72);
}

.content {
  flex-grow: 1;

  height: 100%;
}

.treeContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: auto;
}

.treeItemParent {
  flex-grow: 1;
}

.scrollableWrapper,
.tree {
  height: 100%;
  padding: 0;
}

.header {
  padding: calc(unit * 4) calc(unit * 4) 0;
}

.shortcuts {
  composes: font-smaller-lower;

  margin: unit calc(unit * 4) calc(unit * 2);

  color: secondary-color;

  font-weight: normal;
}

.search {
  width: auto;
  margin-right: calc(unit * 4);
}

.button {
  margin-right: unit;
}

.footer {
  display: flex;
  align-items: baseline;

  box-shadow: inset 0 1px line-color;

  line-height: calc(unit * 3);
}

.resetOrder {
  margin-left: auto;
}

.configureSidebarHint {
  transform: translateX(calc(unit * -2));
}
