@value unit, hover-background-color, icon-secondary-color, font-size-smaller, error-color, link-color, secondary-color, font-family-monospace, font-family from '../../../../../ring-globals.css';

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-height: calc(3 * unit);

  outline: none;
}

.item:hover {
  background-color: hover-background-color;
}

.project-item:hover {
  cursor: pointer;
}

.item-left-column {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
}

.item-name {
  flex: 0 0;

  white-space: nowrap;
}

.item-type-icon {
  margin-right: calc(unit / 2);

  color: icon-secondary-color;
}

.chevron-icon {
  position: relative;

  display: inline;

  cursor: pointer;
}

.item-link {
  margin-right: calc(2 * unit);
}

.disabled-icon {
  visibility: hidden;
}

.incompatibilities-popup {
  padding: calc(unit);
}

.incompatibility {
  overflow: hidden;

  flex: 1 1;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: font-size-smaller;
}

.incompatibility-line {
  display: block;
}

.incompatibility-prop-name {
  color: secondary-color;

  font-family: font-family-monospace;
}

.incompatibility-prop-value {
  color: error-color;

  font-family: font-family-monospace;
}

.show-all-incompatibilities-button {
  flex: 0 0;

  white-space: nowrap;

  color: secondary-color;
}

.assign-item-button {
  flex: 0 0;
}
