@value unit, font-size-smaller, secondary-color, link-hover-color, main-color, border-hover-color from '../../../ring-globals.css';

.healthItems {
  & :global(.healthItemIndicator) {
    margin-left: calc(unit * -0.5);

    padding: 0;

    border: none;
    background: none;
    box-shadow: none;

    font-size: font-size-smaller;

    line-height: inherit;
  }

  & :global(.info.info) {
    color: secondary-color;
  }

  & :global(.healthItemIndicator):focus,
  & :global(.healthItemIndicator)[data-focus-visible-added] {
    outline: none;
    box-shadow: 0 0 0 2px border-hover-color;
  }

  & :global(.healthItemIndicator):hover,
  & :global(.healthItemIndicator):hover *,
  & :global(.healthItemIndicator):focus,
  & :global(.healthItemIndicator):focus *,
  & :global(.healthItemIndicator)[data-focus-visible-added],
  & :global(.healthItemIndicator)[data-focus-visible-added] * {
    color: link-hover-color;
    fill: currentColor;
  }

  & :global(.healthItemIndicator):active,
  & :global(.healthItemIndicator):active * {
    color: main-color;
    fill: currentColor;
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & :global(.healthItemIndicator):hover :global(.info) {
    color: inherit;
  }
}
