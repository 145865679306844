@value unit, contentWidth, textColor, linkHoverColor from '../../../ring-globals.css';

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  max-width: contentWidth;
}

.tabs {
  composes: tabs from '../../common/OverviewTab/OverviewTab.css';

  margin-top: calc(4 * unit);
}

.classic-ui-link {
  display: inline-block;

  height: calc(4 * unit);
  margin: 0 calc(3 * unit);
  padding: 0;

  vertical-align: top;

  color: textColor;

  border: none;
  border-radius: 0;
  outline: none;

  line-height: calc(4 * unit);
}

.classic-ui-link.classic-ui-link:hover {
  text-decoration: none;

  color: linkHoverColor;
  box-shadow: inset 0 -1px 0 0 linkHoverColor;
}

.mammoth-icon {
  vertical-align: middle;

  line-height: calc(2 * unit);
}
