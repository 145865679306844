@value link-color, font-size from '../../../ring-globals.css';

.agentAuth {
  padding: 10px 2px;

  border-top: 1px solid #eee;
}

.open {
  color: link-color;

  font-size: font-size;

  &:hover {
    text-decoration: underline;

    color: link-color;
  }
}
