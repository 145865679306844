@value unit, secondary-color from '../../../ring-globals.css';

.loadingTabs {
  display: inline-flex;

  height: calc(4 * unit);
  margin-left: calc(3 * unit);
  padding: 0;

  vertical-align: top;

  white-space: nowrap;

  color: secondary-color;

  border: none;
  outline: none;

  line-height: calc(4 * unit);
}
