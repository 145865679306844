@value unit, contentWidth from '../../../ring-globals.css';

.page {
  max-width: contentWidth;
}

.heading.heading {
  margin-bottom: calc(2 * unit);
}

.licenses-text {
  margin: calc(2 * unit) 0;
}

.agents {
  composes: container from '../AgentsGroup/AgentsGroup.css';
}

.agent {
  margin: 0 calc(unit * -6) calc(unit / 2) calc(-6 * unit);
  grid-column: start / end;
}
