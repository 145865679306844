.aside {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  display: flex;

  overflow: hidden;
  flex-direction: column;

  box-sizing: border-box;

  height: 100%;
  padding-bottom: calc(var(--ring-unit) * 4);

  padding-left: calc(var(--ring-unit) * 4);
}

.sidebar {
  min-height: 300px;

  animation: moveAnimation 200ms ease-out;

  border: 1px solid var(--ring-line-color);

  border-radius: var(--ring-border-radius);
  background-color: var(--ring-sidebar-background-color);
  box-shadow: 0 2px 8px 0 var(--ring-popup-shadow-color);
}

@keyframes moveAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}
