@value unit, text-color, main-color, font-smaller-lower from '../../../ring-globals.css';

.link {
  white-space: nowrap;

  &:hover {
    text-decoration: none;

    color: text-color;
  }
}

.jumpButton {
  padding: 0 unit;
}

.item {
  composes: item from '../ActionsDropdown/ActionsDropdown.css';
}

.hoverItem {
  composes: hoverItem from '../ActionsDropdown/ActionsDropdown.css';
}

.counter {
  composes: font-smaller-lower;

  margin-left: unit;

  color: main-color;
}

.loader {
  padding: unit unit calc(unit * 2);
}

.marker {
  display: inline-block;

  width: 6px;
  height: 6px;
  margin-left: unit;

  vertical-align: 1px;

  border-radius: 50%;
  background-color: main-color;
}
