@value unit, secondary-color, font-size-smaller from '../../../../../ring-globals.css';

.path {
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  grid-column: start / end;

  min-width: 0;

  margin: unit calc(unit * -2) calc(unit * 0.5);
}

.inHierarchy .path {
  margin-top: 0;
  padding-left: var(--offset);
}

/* stylelint-disable-next-line selector-max-specificity */
.builds + .pathRowGroup:not(.inHierarchy) .path {
  margin-top: calc(unit * 2);
}

.notTriggered {
  margin-left: calc(unit * 2);

  white-space: nowrap;

  color: secondary-color;

  font-size: font-size-smaller;
}

.entityPath {
  flex: 1;

  min-width: 0;
}

.name {
  max-width: calc(100% - unit * 2);
}
