@value unit, secondary-color, ellipsis from '../../../../../ring-globals.css';

.tabs {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  height: calc(unit * 2);

  padding-right: calc(unit * 5);
}

.tab {
  position: relative;

  display: flex;
  align-items: baseline;

  margin-right: calc(unit * 3);
}

.more {
  position: absolute;
  left: calc(100% + unit * 3);

  white-space: nowrap;
}

.moreAnchor {
  height: auto;
}

.link {
  composes: ellipsis;
}

.counter {
  margin-left: unit;

  color: secondary-color;
}

.icon {
  margin-right: calc(unit * -1);

  line-height: inherit;
}
