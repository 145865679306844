@value unit, font, secondary-color, line-height, line-height-lower from '../../../ring-globals.css';

.container {
  overflow: hidden;

  margin: -2px;

  line-height: line-height-lower;
}

.loading {
  visibility: hidden;
}

.buildPath {
  composes: font;

  position: relative;

  margin: 2px;

  text-overflow: ellipsis;

  font-size: inherit;
  line-height: inherit;
}

.item {
  white-space: nowrap;
}

.link {
  color: inherit;
}

.separator {
  margin-right: 1px;

  color: secondary-color;
}

.pathItemDropDown {
  display: inline;
}

.hiddenPathAnchor {
  cursor: default;
}

.hiddenPathPopup {
  padding: unit calc(unit * 2);

  color: secondary-color;
}

.ellipsis {
  color: inherit;
}

.withCollapsing {
  overflow: hidden;

  height: line-height-lower;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.invisible {
  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;
}

.secondary {
  color: secondary-color;
}
