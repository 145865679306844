.actions {
  display: inline-flex;
  align-items: baseline;

  & > :not(:last-child) {
    margin-right: calc(var(--ring-unit) * 1.5);
  }
}

.secondary {
  padding: 0;
}

.close {
  height: auto;

  line-height: inherit;
}

.popupMenu {
  z-index: var(--ring-alert-z-index);
}
