@value unit, icon-secondary-color from '../../../ring-globals.css';

.popup {
  max-width: 300px;

  padding: unit calc(2 * unit);

  text-align: left;
}

.icon {
  color: icon-secondary-color;
}
