@value unit, border-radius, icon-secondary-color, content-background-color, text-color from '../../../../ring-globals.css';

.navigation {
  position: absolute;

  z-index: 1;
  right: calc(unit * 2);
  bottom: calc(unit * 2);
}

.buttons {
  display: flex;
  flex-direction: column;

  border-radius: border-radius;
  box-shadow: 0 2px 16px 0 var(--ring-popup-shadow-color);
}

.button {
  width: 100%;

  &.start.start {
    border-radius: border-radius border-radius 0 0;
  }

  &.end.end {
    margin: -1px 0 0;

    border-radius: 0 0 border-radius border-radius;
  }
}

.tooltip {
  padding: 1px unit 2px;

  pointer-events: none;

  color: text-color;
}
