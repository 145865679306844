@import '../node_modules/@jetbrains/ring-ui/components/global/variables.css';

@value ring: '../node_modules/@jetbrains/ring-ui/components/global/global.css';

/* https://readymag.com/artemtiunov/RingUILanguage/colours/ */

/* Element */
@value line-color: var(--ring-line-color);
@value borders-color: var(--ring-borders-color);
@value icon-color: var(--ring-icon-color);
@value icon-disabled-color: var(--ring-icon-disabled-color);
@value border-hover-color: var(--ring-border-hover-color);
@value border-disabled-color: var(--ring-border-disabled-color);
@value icon-hover-color: var(--ring-icon-hover-color);
@value main-color: var(--ring-main-color);
@value main-hover-color: var(--ring-main-hover-color);
@value icon-main-color: #444;
@value icon-error-color: var(--ring-icon-error-color);
@value icon-warning-color: var(--ring-icon-warning-color);
@value icon-success-color: var(--ring-icon-success-color);
@value icon-secondary-color: var(--ring-icon-secondary-color);
@value popup-border-color: var(--ring-popup-border-color);
@value popup-shadow-color: var(--ring-popup-shadow-color);

body {
  --ring-font-size: 13px;
}

/* Text */
@value search-color: var(--ring-search-color);
@value hint-color: var(--ring-hint-color);
@value link-color: var(--ring-link-color);
@value link-hover-color: var(--ring-link-hover-color);
@value error-color: var(--ring-error-color);
@value warning-color: var(--ring-warning-color);
@value success-color: var(--ring-success-color);
@value text-color: var(--ring-text-color);
@value heading-color: var(--ring-heading-color);
@value secondary-color: var(--ring-secondary-color);
@value disabled-color: var(--ring-disabled-color);

/* Background */
@value content-background-color: var(--ring-content-background-color);
@value sidebar-background-color: var(--ring-sidebar-background-color);
@value selected-background-color: var(--ring-selected-background-color);
@value hover-background-color: var(--ring-hover-background-color);
@value message-background-color: rgba(17, 19, 20, 0.9);
@value navigation-background-color: var(--ring-navigation-background-color);
@value error-background-color: #faebec;
@value success-background-color: #e6f2e9;

/* Metrics */
@value unit from ring; /* use a multiple of this (e.g. calc(3 * unit)) for margins, paddings etc. */
@value border-radius: var(--ring-border-radius);
@value border-radius-small: var(--ring-border-radius-small);
@value font-size-larger: var(--ring-font-size-larger);
@value font-size: var(--ring-font-size);
@value font-size-smaller: var(--ring-font-size-smaller);
@value line-height-taller: var(--ring-line-height-taller);
@value line-height: var(--ring-line-height);
@value line-height-lower: var(--ring-line-height-lower);
@value line-height-lowest: var(--ring-line-height-lowest);
@value ease: var(--ring-ease);
@value fast-ease: var(--ring-fast-ease);
@value font-family: var(--ring-font-family);
@value font from ring;
@value font-lower from ring;
@value font-smaller from ring;
@value font-smaller-lower from ring;
@value font-larger-lower from ring;
@value font-larger from ring;
@value font-family-monospace: var(--ring-font-family-monospace);
@value thin-font from ring;
@value monospace-font from ring;
@value input-disabled from ring;
@value ellipsis from ring;

/* Common z-index-values */

/* Invisible element is an absolutely positioned element which should be below */
/* all other elements on the page */
@value invisible-element-z-index: var(--ring-invisible-element-z-index);

/* z-index for position: fixed elements */
@value fixed-z-index: var(--ring-fixed-z-index);

/* Elements that should overlay all other elements on the page */
@value overlay-z-index: var(--ring-overlay-z-index);

/* Alerts should de displayed above overlays */
@value alert-z-index: var(--ring-alert-z-index);

/* Note: footer also has top margin which isn't taken into account here */
@value footer-height from ring;

/* Media breakpoints (minimal values) */
@value breakpoint-small from ring;
@value breakpoint-middle from ring;
@value breakpoint-large from ring;

/* Media queries */
@value extra-small-screen-media from ring;
@value small-screen-media from ring;
@value middle-screen-media from ring;
@value large-screen-media from ring;
@value resetButton from ring;

/* Constants */
/* Kebab-case not working in calc */
@value contentWidth: 1600px;
@value success-progress-color: #7acc8b;

/* https://a11yproject.com/posts/how-to-hide-content/ */

/* stylelint-disable-next-line selector-max-specificity */
.visuallyHidden,
.visuallyHiddenContainer:not(:hover):not(:global(.focus-visible)):not(:active) .visuallyHiddenChild {
  position: absolute;

  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  width: 1px;
  height: 1px;

  white-space: nowrap; /* added line */
}

.lineClamp {
  position: relative;

  overflow: hidden;
  overflow-wrap: break-word;
}

.lineClampHasOverflow::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: 1px;

  content: '\2060';

  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@supports (-webkit-line-clamp: 2) {
  .lineClamp {
    display: -webkit-box;

    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
  }

  .lineClampHasOverflow::after {
    display: none;
  }
}
