@value unit, error-color, success-color, secondary-color, font-size-smaller from '../../../ring-globals.css';

.label {
  font-size: font-size-smaller;
  font-weight: bold;
}

.descriptions {
  color: secondary-color;
}

.character {
  color: success-color;
}

.character:nth-child(2n + 1) {
  color: error-color;
}

.title {
  margin-right: calc(unit / 2);
}
