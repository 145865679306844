@value unit, text-color, link-hover-color, secondary-color, icon-secondary-color from '../../../../ring-globals.css';

.path {
  display: flex;

  overflow: hidden;
  flex-direction: row-reverse;

  flex-wrap: wrap;
  justify-content: flex-end;

  height: 22px;
  margin: 0 -2px;
  padding: 0 2px;
}

.path-item {
  white-space: nowrap;
}

.parent-item-container {
  margin-right: calc(-2 * unit);

  background: white;
}

.target-item {
  overflow: hidden;

  min-width: 0;
  margin: 0 -2px;
  padding: 0 2px;

  text-overflow: ellipsis;
}

.ellipsis {
  width: calc(1.5 * unit);

  cursor: default;
}

.ellipsis,
.separator {
  margin-left: calc(0.5 * unit);
}

.parentsDropDownAnchor {
  display: inline;
}

.parentsPopup {
  padding: unit calc(2 * unit);

  color: secondary-color;
}

.separator {
  color: secondary-color;
}

.link {
  color: text-color;
}

.parent-item {
  color: secondary-color;
}

.icon {
  margin: 0 calc(0.5 * unit);

  color: icon-secondary-color;
}
