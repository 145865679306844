@value unit, error-color, icon-error-color, secondary-color from '../../../ring-globals.css';

.icon {
  margin-right: calc(unit / 2);
}

.disabled {
  color: secondary-color;
}

.disconnected {
  color: error-color;
}

.disconnected-icon {
  color: icon-error-color;
}
