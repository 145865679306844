@value loader from '../../../../BuildPage/DependenciesTab/DependenciesChain/DependenciesChain.css';

.container {
  margin-bottom: 2px;

  border-bottom: 1px solid var(--ring-line-color);
}

.innerContainer {
  margin: 0 0 calc(var(--ring-unit) * -5) calc(var(--ring-unit) * -2);
}

.container .loader {
  padding-top: 0;
  padding-bottom: calc(var(--ring-unit) * 7);
}
