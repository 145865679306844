.input {
  background-color: var(--ring-content-background-color);
}

.multiline {
  min-height: 64px;
}

.container {
  position: relative;
}
