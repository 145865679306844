@value unit from '../../../../ring-globals.css';

.header {
  justify-content: flex-start;

  margin-bottom: unit;
}

.toggleContainer {
  display: block;

  margin-right: unit;
}
