@value unit, line-height, font-smaller, font-smaller-lower, border-radius, secondary-color, error-color, icon-main-color, icon-error-color, success-color, text-color, success-background-color, error-background-color, success-progress-color from '../../../../ring-globals.css';
@value lineHeight: line-height;
@value wrapper-width: calc(unit * 14);

.buildDuration {
  composes: font-smaller;

  display: inline-flex;
  align-items: baseline;
  justify-content: flex-end;

  margin-left: calc(0 - unit);
  padding-left: unit;

  text-align: right;

  white-space: nowrap;

  font-weight: normal;
}

.placeholder {
  width: wrapper-width;

  &::before {
    content: '.';

    color: transparent;
  }
}

.low {
  composes: font-smaller-lower;
}

.text {
  padding-right: unit;
}

.textForOvertime {
  padding-right: 0;
  padding-left: 20px;
}

.icon {
  position: absolute;
  top: 1px;
  left: 2px;

  color: inherit;
}

.wrapper {
  composes: font-smaller;

  position: relative;
  z-index: 1;

  overflow: hidden;

  width: wrapper-width;

  margin: 0;
  padding: 0 0 1px;

  cursor: default;

  white-space: nowrap;

  border-radius: border-radius;
}

.wrapperSuccess {
  color: text-color;
  background-color: success-background-color;

  & .icon {
    color: icon-main-color;
  }
}

.wrapperFailure {
  color: error-color;
  background-color: error-background-color;

  & > .icon {
    color: icon-error-color;
  }
}

.wrapperSuccess.with-border {
  box-shadow: 0 0 0 1px rgba(122, 204, 139, 0.5) inset;
}

.wrapperFailure.with-border {
  box-shadow: 0 0 0 1px rgba(194, 39, 49, 0.5) inset;
}

@media (min-resolution: 2dppx) {
  .wrapperSuccess.with-border {
    box-shadow: 0 0 0 0.5px success-progress-color inset;
  }

  .wrapperFailure.with-border {
    box-shadow: 0 0 0 0.5px error-color inset;
  }
}

.wrapperOvertime {
  text-align: left;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  height: calc(lineHeight + 1px);

  text-align: right;

  border-radius: border-radius 0 0 border-radius;
  background-color: success-progress-color;
}

.progressFailure {
  color: var(--ring-white-text-color);
  background-color: error-color;
}

.progressOvertime {
  text-align: left;
}

.startedFinished {
  color: secondary-color;
}

.durationFinished {
  margin-left: unit;
}

.firstRunSuccessful {
  color: success-color;
}

.firstRunFailure {
  color: error-color;
}

.exactDuration {
  display: inline-block;

  min-width: calc(12 * unit);

  margin-left: 0;
}

.exactStarted {
  margin-left: 0;
}
