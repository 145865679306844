@value unit, success-color, error-color, text-color from '../../../ring-globals.css';

.status {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon {
  margin-right: calc(unit / 2);
}

.success {
  color: success-color;
}

.failed {
  color: error-color;
}

.muted,
.ignored {
  color: text-color;
}
