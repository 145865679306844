.cell {
  box-sizing: border-box;
  height: calc(var(--ring-unit) * 4);
  padding-top: calc(var(--ring-unit));
}

.duration {
  text-align: end;
}

.status,
.number {
  display: block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.estimate {
  color: var(--ring-secondary-color);
}

.stop {
  margin-right: calc(var(--ring-unit) * -1);
}
