@value unit from '../../../ring-globals.css';

.info {
  padding: unit 0;
}

.infoIcon {
  margin-right: unit;
  margin-left: 1px;
}
