@value unit, success-color, error-color, font-size from '../../../../ring-globals.css';
@value awaits-color: #cc8b00;
@value refuse-color: error-color;
@value approved-color: success-color;

.approveButton {
  margin-top: calc(unit / 2);
  margin-right: unit;

  font-size: font-size;
}
