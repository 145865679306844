@value unit, success-color, error-color, font-size from '../../../ring-globals.css';
@value awaits-color: #cc8b00;
@value refuse-color: error-color;
@value approved-color: success-color;

.popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .user {
    display: block;

    padding-bottom: calc(unit / 2);
  }

  & .user:not(.withAvatar) {
    padding: 0;
  }

  & .status {
    padding-left: calc(unit / 2);
  }

  & .noApproves {
    display: block;
  }
}

.inline {
  /* stylelint-disable-next-line selector-max-specificity */
  & .user.withAvatar:not(.folded) {
    padding-right: calc(unit);
  }

  /* stylelint-disable-next-line selector-max-specificity */
  & .user.user:last-of-type {
    padding-right: calc(unit * 1.5);
  }

  & .username {
    padding-right: calc(unit / 2);
  }

  & .group {
    padding-right: calc(unit);
  }

  & .group .username {
    padding-left: calc(unit / 2);
  }
}

.group .approved,
.approved .status {
  color: success-color;
}

.group .awaits,
.approvalsLeft,
.awaits .status {
  color: awaits-color;
}
