@value unit from '../../../../../ring-globals.css';

.filter {
  display: flex;
  align-items: center;
}

.input {
  width: calc(30 * unit);
  margin: 0;
}

.hide-button {
  margin-left: calc(2 * unit);
}
