@import '@jetbrains/ring-ui/components/global/variables.css';

@value unit, line-color, success-color, error-color from '../../../ring-globals.css';

/* pure brandbook colors */
@value tc-purple: #9135e0;
@value tc-blue: #05c1fd;
@value tc-green: #18d68c;

.qr {
  display: block;

  background: linear-gradient(90deg, tc-purple, tc-blue, tc-green);
}

.qr-wrapper {
  margin-top: unit;
  padding: unit;

  border: 1px solid line-color;
}

.key-representations {
  display: flex;

  margin-top: calc(unit * 1.5);

  & > div {
    margin-right: calc(unit * 4);
  }
}

.confirmation-form {
  margin-top: calc(unit * 2);
}

.password.password /* to override ring ui */ {
  width: calc(264px + 2 * unit);
  margin-bottom: calc(unit * 2);
}

.password-inner {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
}

.delete {
  margin-top: calc(unit / 2);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.error {
  animation: fade-in 0.2s;

  color: error-color;
}

span.error {
  margin-left: calc(unit * 2);
}

.recovery-keys-with-actions {
  display: flex;

  margin-top: unit;
}

.recovery-keys {
  padding: calc(unit / 2) unit;

  white-space: pre;

  background: var(--ring-sidebar-background-color);

  font-family: monospace;
}

.actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-left: unit;
}

.heading {
  margin-top: calc(4 * unit);

  font-size: inherit;
  font-weight: bold;
}

.green-heading {
  composes: heading;

  color: success-color;
}

.configuration-form {
  margin-top: -12px;
}

.popup {
  padding: unit calc(2 * unit);
}
