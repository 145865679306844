@value font-size-smaller, secondary-color, success-color from '../../../../ring-globals.css';

.couter {
  padding-right: 0;

  padding-left: 0;

  color: secondary-color;

  font-size: font-size-smaller;
}

.running-count {
  color: success-color;
}

.couter:hover .running-count {
  color: inherit;
}
