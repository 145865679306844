@value unit, secondary-color, icon-secondary-color, hover-background-color, font-size-smaller, popup-border-color from '../../../../ring-globals.css';

.loader {
  display: block;

  width: 16px;

  margin: 0 calc(4 * unit);
}

.dialog {
  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.dialog-content {
  display: flex;
  flex: 1 0;
  flex-direction: column;

  width: calc(unit * 66);
  height: calc(100vh - unit * 8);
  max-height: calc(unit * 72);
}

.header.header {
  flex: 0 0;

  margin: calc(2 * unit) calc(4 * unit);

  font-size: calc(3 * unit);
}

.search-input {
  flex: 0 0;

  margin-right: calc(4 * unit);
  margin-left: calc(4 * unit);
}

.tree {
  display: flex;
  flex: 1 0;

  margin-top: calc(0.5 * unit);
}

.empty-tree {
  flex: 1 0;

  padding: 0 calc(4 * unit);
}

.tree-item {
  height: calc(3 * unit);

  cursor: default;

  line-height: calc(3 * unit);
}

.tree-item:hover {
  background-color: hover-background-color;
}

.tree-item-project {
  padding: 0 calc(3.75 * unit);
}

.tree-item-action {
  flex: 0 0;
}

.tree-item-action[disabled] {
  cursor: default;
  pointer-events: all;
}

.panel.panel {
  flex: 0 0;

  padding-left: calc(4 * unit);

  border-top: 1px solid popup-border-color;
}

.diassociate-checkbox {
  display: block;

  margin-bottom: calc(2 * unit);
}
