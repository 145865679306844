@value unit, font-size, main-color from '../../ring-globals.css';

.wrapper {
  min-width: calc(unit * 120);
  padding: calc(unit * 1) 0;

  text-align: left;
}

.content {
  display: flex;
}

.description {
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;

  margin-top: calc(unit * 1.5);
}

.image {
  width: calc(unit * 60);
  margin: calc(unit * 2) 0 unit auto;
}

.header.header {
  display: flex;

  margin: calc(unit * 1.5) 0 calc(unit * 2);

  font-size: 24px;
}

.text {
  margin-top: 0;
  margin-bottom: calc(unit * 1.5);
  padding: 0;

  font-size: 14px;
  line-height: 1.43;
}

.button {
  font-size: 12px;
}

.link {
  margin-left: unit;
}
