@value unit, warning-color, error-color, font-size-smaller, line-height-lowest from '../../../../../ring-globals.css';

.dialog {
  width: auto;
}

.dialogContent {
  padding-top: unit;
}

.expirationInfo {
  margin-top: calc(unit / 2);

  font-size: font-size-smaller;
}

.infoMargin {
  margin-top: unit;
}

.warning {
  color: warning-color;
}

.expired {
  color: error-color;
}

.renewalBtn {
  margin: 0 calc(unit / 2);

  color: var(--ring-button-primary-background-color);
}
