.legend {
  composes: legend from '../../DependenciesTimeline/Legend/Legend.css';

  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-top: var(--ring-unit);
  margin-bottom: 0;

  white-space: nowrap;
}

.marker {
  --size: 14px;

  composes: marker from '../../DependenciesTimeline/Legend/Legend.css';

  vertical-align: -2px;
}

.run {
  margin-right: calc(var(--ring-unit) * 0.5);

  color: var(--ring-icon-color);
}

.unrelated {
  composes: marker;

  border: 1.5px dashed var(--ring-borders-color);
}

.circle {
  composes: marker;

  box-shadow: 0 0 0 1.5px var(--ring-borders-color) inset;
}

.stack {
  composes: stack from '../DependenciesChain.css';

  &::before {
    display: none;
  }
}

.selected {
  --offset: calc(var(--ring-unit) * 0.5);

  composes: circle;

  position: relative;

  margin: 0 calc(var(--ring-unit) * 0.5 + var(--offset)) 0 var(--offset);

  &::before {
    position: absolute;
    top: calc(var(--offset) * -1);
    right: calc(var(--offset) * -1);
    bottom: calc(var(--offset) * -1);
    left: calc(var(--offset) * -1);

    content: '';

    border: 2px solid var(--ring-main-color);

    border-radius: 50%;
  }
}

.reused {
  --offset: 5px;

  composes: circle;

  position: relative;

  margin: 0 calc(var(--ring-unit) * 0.5 + var(--offset)) 0 var(--offset);

  &::before {
    position: absolute;
    top: calc(var(--offset) * -1);
    right: calc(var(--offset) * -1);
    bottom: calc(var(--offset) * -1);
    left: calc(var(--offset) * -1);

    content: '';

    border-radius: 50%;

    background-color: rgba(237, 162, 0, 0.3);
  }
}
