@value unit, font-size-smaller, warning-color from '../../../ring-globals.css';
@value blue-background-color: #f2f9ff;
@value main-background-color: #fff;

.options-box {
  display: flex;

  margin: calc(unit / 2) 0;
  padding: 0 calc(unit * 2);
}

.code-wrapper {
  margin-top: 0;
  margin-bottom: 0;

  white-space: pre-wrap;

  & code {
    overflow: visible;

    padding-top: 0;
    padding-bottom: calc(unit / 4);

    border-radius: 0;

    font-size: font-size-smaller;
    line-height: 1.5;
  }
}

.blue {
  & code {
    background-color: blue-background-color;
  }
}

.white {
  & code {
    background-color: main-background-color;
  }
}

.additional-indent {
  margin-left: calc(unit * 8);
}

.small-indent {
  margin-left: calc(unit * 4);
}

.error {
  max-width: calc(unit * 100);

  margin: calc(unit);

  color: warning-color;
}

.icon-button {
  padding-right: 0;
  padding-left: 0;
}

.icon-wrapper {
  margin-right: calc(unit / 2);
}

.codeSection {
  overflow: auto;
}
