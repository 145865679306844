@value fixed-z-index from '../../../ring-globals.css';

.middle-ellipsis {
  position: relative;

  display: inline-block;

  max-width: 100%;

  white-space: nowrap;

  text-decoration: inherit;
}

.visible {
  position: relative;

  display: inline-flex;

  align-items: baseline;

  max-width: 100%;

  text-decoration: inherit;

  pointer-events: none;

  &::before,
  &::after {
    display: block;

    text-decoration: inherit;
  }

  &::before {
    overflow: hidden;

    min-width: 0;

    content: attr(data-head);
    text-overflow: ellipsis;
  }

  &::after {
    flex-shrink: 0;

    content: attr(data-tail);
  }
}

/* Fix invisible selection in FF */
@-moz-document url-prefix('') {
  .visible::before,
  .visible::after {
    z-index: fixed-z-index;
  }
}

.searchable {
  position: absolute;

  top: 0;
  right: 0;
  left: 0;

  overflow: hidden;

  color: transparent;
}
