@value unit, font-size, line-height-lower, secondary-color from '../../../../../ring-globals.css';

.form {
  display: flex;
}

.inputContainer {
  margin-right: calc(unit * 2);
}

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.noResults {
  margin-right: unit;
  padding-top: 1px;

  &.loading {
    color: secondary-color;
  }
}

.progress {
  display: inline-block;

  width: calc(unit * 2);
  margin-left: unit;

  padding-top: 1px;
}

.reset {
  margin-left: unit;
}
