@value unit, text-color, font-size, ellipsis, secondary-color from '../../../../ring-globals.css';
@value link from '../../../common/BuildNumber/BuildNumber.css';

.wrapper {
  display: flex;
  align-items: baseline;
}

.popupContent {
  padding: calc(unit * 2);
  padding-top: calc(unit * 1.5);
}

.anchor {
  color: text-color;
}

.buildPath {
  composes: ellipsis;

  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;

  margin-bottom: calc(unit / 2);

  &:last-child {
    margin-bottom: 0;
  }
}

.buildNumber .link {
  font-size: font-size;
}

.separator {
  color: secondary-color;
}

.chainLink {
  margin-left: calc(unit * 1.5);
}
