.button {
  composes: job from '../JobTile/JobTile.css';

  margin: 0;

  cursor: pointer;

  text-align: left;

  color: var(--ring-secondary-color);
  border: 1px dashed var(--ring-icon-color);
  background: transparent;

  font-family: var(--ring-font-family);
  font-weight: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &:hover {
    color: var(--ring-icon-hover-color);
    border-color: var(--ring-icon-hover-color);
    background-color: transparent;
  }

  &[disabled] {
    pointer-events: none;

    color: var(--ring-secondary-color);
    border-color: var(--ring-border-disabled-color);
  }
}

.icon {
  composes: icon from '../JobTile/JobTile.css';

  color: var(--ring-icon-color);
}

.name {
  composes: name from '../JobTile/JobTile.css';
}

.button:hover .name {
  background-color: transparent;
}
