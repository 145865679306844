@value unit, line-color, font-size-smaller, secondary-color, content-background-color from '../../../../ring-globals.css';

.header {
  padding: unit calc(unit * 2) 0 calc(unit * 2);

  border-bottom: 1px solid line-color;

  background-color: content-background-color;

  &.extraPadding {
    padding-right: calc(unit * 4);
    padding-left: calc(unit * 4);
  }
}

.controls {
  display: flex;

  min-height: calc(unit * 5 + 2px);
}

.rightControls {
  margin-left: auto;
  padding-left: calc(unit * 2);

  text-align: right;

  font-size: font-size-smaller;
}

.settingsAction {
  margin-left: calc(unit * 1.5);
  padding: 0;

  color: secondary-color;
}
