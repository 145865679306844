@value ellipsis from '../../../ring-globals.css';
@value buttonContainer from '@jetbrains/ring-ui/components/select/select.css';

.select .buttonContainer {
  display: flex;
}

.selectButton {
  composes: ellipsis;

  min-width: calc(var(--ring-unit) * 30);
  max-width: calc(var(--ring-unit) * 50);
}
