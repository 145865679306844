.historyLink {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.historyChevron {
  margin-left: -3px;
}

.historyChevron.historyChevron > svg {
  vertical-align: -4px;
}

.branchAndStatus {
  margin-top: 3px;
}

.branch {
  margin-right: calc(var(--ring-unit) * 1.5);
}

.status-success.status-success {
  color: var(--ring-success-color);
}

.status-failure.status-failure {
  color: var(--ring-error-color);
}

.status-queued.status-queued,
.status-canceled.status-canceled {
  color: var(--ring-secondary-color);
}

.triggered {
  margin-top: calc(var(--ring-unit) / 2);
  margin-bottom: var(--ring-unit);
}

.headingWrapper {
  display: flex;
}

.actions {
  margin-left: auto;
}

.button {
  margin-left: var(--ring-unit);
}
