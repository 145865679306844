@value icon-secondary-color from '../../../../ring-globals.css';

.icon {
  display: inline-block;

  background-repeat: no-repeat;

  line-height: normal;
}

.file {
  opacity: 0.8;
  color: #9aa7b0;
}
