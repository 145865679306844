@value unit, link-hover-color, selected-background-color from '../../../../ring-globals.css';

.flash.flash,
.flash .queuedIcon {
  transition: none;

  color: link-hover-color;
}

.flash.flash.extraHighlight {
  background-color: selected-background-color;
}
