@value unit, font-size, line-height from '../../../../ring-globals.css';
@value htmlHeading, chevronIcon from '../../../common/ContentPanel/ContentPanel.css';
@value glyph from '@jetbrains/ring-ui/components/icon/icon.css';

.content {
  position: relative;

  margin: 0 calc(unit * -4) calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.content .htmlHeading {
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}

.content .chevronIcon .glyph {
  vertical-align: -2px;
}

.firstInfoGroup {
  margin-bottom: calc(unit * 2);
}

.secondInfoGroup {
  margin-bottom: calc(unit * 4);

  & .row {
    margin-bottom: unit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.notes:not(:empty) {
  margin-bottom: calc(unit * 1.5);
}

.warning {
  margin-bottom: calc(unit / 2);
}

.snapshotDependenciesChart {
  width: 40%;
}

@media (max-width: 1200px) {
  .snapshotDependenciesChart {
    width: 60%;
  }
}

.buildProgress,
.buildLog,
.loader {
  margin-bottom: calc(unit * 4);
}
