@value font-smaller, secondary-color from '../../../ring-globals.css';

.compatible-agents {
  composes: font-smaller;
}

.anchor {
  height: 20px;

  padding: 0 4px;

  line-height: 20px;
}

.secondary {
  color: secondary-color;
}
