@value secondary-color, unit, error-color from '../../../../ring-globals.css';

.warning {
  display: inline-block;

  margin-top: calc(unit * 2);

  color: secondary-color;

  line-height: calc(unit * 3);
}

.button {
  padding: 0 calc(unit / 2);

  font-size: 13px;
}

.loader {
  align-self: flex-start;

  margin-left: calc(unit / 2);

  opacity: 0;
}

.loading {
  transition: opacity 0.3s ease-in;

  opacity: 1;
}

.error {
  color: error-color;
}
