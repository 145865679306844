@value font, font-size-smaller, unit, secondary-color, link-hover-color from '../../../../../../../ring-globals.css';

.buildTypeStatus {
  composes: font;

  height: calc(unit * 5);

  font-size: font-size-smaller;
}

.queued {
  margin-top: -2px;
  margin-right: calc(unit * 2);
}

.queuedAnchor {
  color: secondary-color;

  line-height: normal;
}

.flash {
  transition: none;

  color: link-hover-color;
}

.queuedPopup {
  max-height: 45vh;
  padding: calc(unit / 2) unit calc(unit * 2);
}

.pending {
  font-size: font-size-smaller;
}

.pending,
.loading {
  color: secondary-color;
}
