@value unit, icon-secondary-color, font-size-smaller, text-color, line-height, error-color from '../../../ring-globals.css';

.wrapper {
  margin-bottom: calc(unit * 2);

  &.expanded {
    margin-bottom: calc(unit * 4);
  }
}

.header {
  display: flex;
  align-items: baseline;

  margin-bottom: unit;
}

.expandable {
  & .header {
    margin-left: calc((unit * -2) - 2px);
  }
}

.htmlHeading.htmlHeading {
  display: flex;
  align-items: baseline;

  height: line-height;

  margin: 0;
  padding: 0;

  font: inherit;
  font-weight: bold;
}

.subheading {
  font-size: font-size-smaller;
  font-weight: normal;
}

.heading,
.subheading {
  margin-right: unit;

  color: text-color;
}

.content.border {
  padding: calc(unit * 2);
  padding-top: calc(unit * 1.5);

  border: 1px solid #dfe5eb;

  border-radius: 3px;
}

.chevronIcon {
  margin-right: calc(unit / 2);

  cursor: pointer;

  color: icon-secondary-color;
}

.chevronIcon,
.heading,
.subheading {
  line-height: line-height;
}

.errorHeading {
  color: error-color;
}
