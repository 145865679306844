.secondaryHeader {
  padding: calc(var(--ring-unit) * 2) 0;
}

.headerButton {
  composes: headerText from '../SidebarPrimaryContent/SidebarPrimaryContent.css';

  margin-left: calc(var(--ring-unit) * -2.5);
}

.backIcon {
  margin-right: 2px;
  padding-top: 1px;

  &.backIcon svg {
    vertical-align: -4px;
  }
}
