@value unit, secondary-color from '../../../../ring-globals.css';
@value nodes from '../../../common/Tree/Tree.css';
@value item, label from '../../../common/Tree/TreeNode/TreeNode.css';

.filterItem {
  margin-right: calc(unit * 2);
  margin-bottom: calc(unit * 2);
}

.emptyList {
  color: secondary-color;
}

.grid {
  padding: 0 calc(unit * 2);
}

.tree .grid {
  margin-right: calc(unit * 2);
  padding-left: 0;
}

.tree {
  margin: 0 calc(unit * -4) 0 calc(unit * -4 - 2px);
  padding: 0 calc(unit * 2);
}

.buildType,
.buildType > .item,
.buildType > .item > .label {
  display: contents;
}

.infiniteScroll {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);
}

.loader {
  padding-top: calc(unit * 2);
}

.loadMoreButtonWrapper {
  display: flex;

  align-items: center;
  flex-direction: column;

  padding-top: calc(unit * 2);
}

.showAllButton {
  color: secondary-color;
}

.row {
  display: contents;
}
