@value unit, text-color, line-height from '../../../../ring-globals.css';

.button {
  line-height: line-height;
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}
